<!-- HEADER -->
<div class="modal-header">
  <h5 class="modal-title pull-left">{{this.pageTitle}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- BODY -->
<div class="modal-body">
  <div class="row mb-3">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <h4>Time Zone Settings</h4><hr>
    </div>
  </div>
  <form
    class="pl-2 pr-2"
    [formGroup]="settingsForm"
    (ngSubmit)="onSubmitForm()">
    <div class="row mb-2">
      <div class="col-sm-10 col-md-6 col-lg-6">
        <label class="text-dark">Use Organization Time Zone</label>
      </div>
      <div class="col-sm-2 col-md-6 col-lg-6">
        <p-inputSwitch
          formControlName="orgTimeZone"
          (onChange)="onTimeZoneChange()">
        </p-inputSwitch>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <label class="text-dark">Current Time Zone</label>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <label class="val label sec-label text-uppercase">{{selectedTimeZone}}</label>
      </div>
    </div>
  </form>
</div>

<!-- FOOTER -->
<div class="modal-footer">
  <div class="btn-con">
      <button
        class="btn submit-btn"
        (click)="onSubmitForm()">Confirm
      </button>
      <button
        class="btn cancel-btn"
        (click)="onCancel()">Close
      </button>
  </div>
</div>
