import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@utilities/app-constants';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  // Constants
  redirectUrls: any;

  constructor() {
    this.redirectUrls = AppConstants.redirectUrls;
   }

  ngOnInit(): void {
  }

}
