import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private spinner: NgxSpinnerService,
    private loadingBarService: LoadingBarService
  ) { }

  
  public startLoaders() {
    this.loadingBarService.start();
    this.showSpinner();
  }

  public stopLoaders() {
    this.loadingBarService.stop();
    this.hideSpinner();
  }

  public showSpinner() {
    this.spinner.show();
  }

  public hideSpinner() {
    this.spinner.hide();
  }
}
