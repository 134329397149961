import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMsg } from '@models/alert-msgs';
import { ResetPasswordData, ResetPasswordResponse } from '@models/reset-password';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AuthService } from '@services/auth/auth.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { requiredPattern } from '@utilities/validators';
import { Subscription } from 'rxjs';
import { ResetPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  // Constants
  errMsgs: any;
  redirectUrls: any;
  pageTitle: string;
  fieldValidations: any;
  generalValidations: any;
  activationCode: any;

  // Subscriptions
  routeSubscription$ = new Subscription();

  // Form related variables
  resetPasswordForm: FormGroup;

  // Flags
  showPassword: boolean;
  isFormSubmitted: boolean;
  showConfirmPassword: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private alertMessageService: AlertMsgService,
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.activationCode = '';
    this.authService.purgeAuth();
    this.subscribeToRouterParams();
    this.showPassword = false;
    this.showConfirmPassword = false;
    this.isFormSubmitted = false;
    this.redirectUrls = AppConstants.redirectUrls;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;
    this.pageTitle = AppConstants.pageTitles.resetPassword;
    this.fieldValidations = InputFieldLimits.signupForm;
    this.generalValidations = InputFieldLimits.generalValidations;
  }

  ngOnInit(): void {
    this.initialiseResetPasswordForm();
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Extracts the user key from the url and makes the API request for activating
   * the user account
   */
  subscribeToRouterParams() {
    this.routeSubscription$ = this.activatedRoute.queryParams
      .subscribe(params => {
        if (params && params.activation_code) {
          this.activationCode = params.activation_code;
        } else {
          this.showErrorToastr();
        }
      });
  }

  /**
    * Author: Jeswin Chacko P A
    * Description: Displays error alert for failed api response
    */
  showErrorToastr() {
    const alert: AlertMsg = {
      msg: ToastrMessages.RESETPASSWORD_ACTIVATION.activateResetPasswordFail,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.alertMessageService.showErrorToster(alert);
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Initialises resetpassword form with necessary form fields
   */
  initialiseResetPasswordForm() {
    this.authService.purgeAuth();
    localStorage.clear();
    this.sessionStorageService.clearLocalStorageValues();
    this.resetPasswordForm = this.fb.group({
      new_password: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
      confirm_password: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
      },
    );
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Validates the form and calls the post API method based on the result
   */
  onResetPassword() {
    this.isFormSubmitted = true;
    if (this.resetPasswordForm.valid) {
      const ResetPasswordData: ResetPasswordData = this.resetPasswordForm.value;
      this.postResetPasswordInfo(ResetPasswordData);
    }
  }

  /**
   * Author: Jeswin Chacko P A
   * @param { LoginInfo } ResetPasswordData
   * Description: Send the reset-password info to the  API
   */
  postResetPasswordInfo(ResetPasswordData: ResetPasswordData) {
    let requestData: ResetPasswordData = this.constructRequestData(ResetPasswordData);
    this.resetPasswordService.postResetPasswordInfo(requestData).subscribe(
      (response: ResetPasswordResponse) => {
        if (response.status) {
            this.utilityService.showResponseSuccess(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordSuccess, response.message);
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
        } else {
          this.utilityService.showResponseError(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordFail, error.error.message );
        }
    }
    );
  }

  /**
    * Author: Jeswin Chacko P A
    * Description: COnstructs requestdata for reset-password API request
    */
  constructRequestData(ResetPasswordData: ResetPasswordData): ResetPasswordData {
    ResetPasswordData.reset_token = this.activationCode;
    ResetPasswordData.new_password = this.utilityService.hashPassword(ResetPasswordData.new_password);
    ResetPasswordData.confirm_password = this.utilityService.hashPassword(ResetPasswordData.confirm_password);
    const requestData: ResetPasswordData = ResetPasswordData;
    return requestData;
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Toggles the flag which controls password visibility
   */
  togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Toggles the flag which controls confirm password visibility
   */
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onCancelClick() {
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

}
