export class ToastrMessages {

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Contains an oject with custom error messages for HTTP errors
     */
    public static HTTP_ERROR_MESSAGES = {
        ERROR_GENERIC: 'Some error occured',
        ERROR_INTERNAL: 'Something went wrong on the server.',
        ERROR_NETWORK: 'Something Went Wrong, Contact Administrator or Please check your internet connectivity',
        ERROR_FALIURE_0: 'API call failed',
        ERROR_BAD_REQUEST_400: 'Bad request.',
        ERROR_UNAUTHORIZED_ACCESS_401: 'Unauthorized access! Please login',
        ERROR_FORBIDDEN_403: 'Forbidden.',
        ERROR_NOT_FOUND_404: 'Not found.',
        ERROR_AUTHENTICATION_ERROR_429: 'Something went wrong, please refresh the page.',
        ERROR_SERVER_ERROR_500: 'Internal server error.',
        ERROR_SERVICE_ERROR_503: 'Service unavailable.',
        ERROR_GATEWAY_ERROR_504: 'Gateway timeout error, please try again later.',
    };

    public static AUTH_MESSAGES = {
        logoutSuccess: 'You have been logged out  of the application',
        logoutFail: 'Logout failed',
        unauthorizedAccess: 'You are not authorized to view this page',
        userInfoFailFetch: 'Failed to fetch user info'
    }

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Account verification related messages
     */
    public static ACCOUNT_ACTIVATION = {
        activateAccountSuccess: 'Account activated successfully',
        activateAccountFail: 'Oops! Account activatation failed',
    };

    public static FILE_VALIDATIONS = {
        size: 'File size cannot exceed ',
        type: 'Invalid file type'
    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores Generic titles of alert messages
     */
    public static TOASTR_TITLES = {
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Contains the common form error messages
     */
    public static FORM_ERROR_MSGS = {
        invalidFormat: 'Invalid Format',
        invalidEmail: 'Invalid email address',
        OTPPattern: 'Please enter a valid OTP',
        fileUpload: 'File uploaded successfully',
        invalidCost: 'Please enter a valid amount',
        mandarotyFields: 'This field is mandatory',
        fileUploadFail: 'Oops! File upload failed',
        invalidMobileNumber: 'Invalid mobile number',
        mandatoryToast: 'Please fill all the mandatory fields',
        passwordPattern: 'Password does not meet the criteria',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in login page
     */
    public static LOGIN_FORM_MSGS = {
        loginSuccess: 'Login Successful',
        loginFail: 'Login Failed',
        logoutFail: 'Logout Failed',
        logoutSuccess: 'Logout Success',
        noPermissionAssigned: 'You are not granted any permissions. Please contact the administrator',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in signup page
     */
    public static SIGNUP_FORM_MSGS = {
        signupSuccess: 'Signup Successful',
        signupFail: 'Signup Failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Alert messages in forgot-password page
     */
    public static FORGOTPASSWORD_FORM_MSGS = {
        forgotPasswordSuccess: 'Reset Link Sent Successfully',
        forgotPasswordFail: 'Reset Link Senting Failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Account verification related messages
     */
    public static RESETPASSWORD_ACTIVATION = {
        activateResetPasswordSuccess: 'Reset Password activated successfully',
        activateResetPasswordFail: 'Oops! Reset Password activatation failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Alert messages in reset-password page
     */
    public static RESETPASSWORD_FORM_MSGS = {
        resetPasswordSuccess: 'Password Changed Successfully',
        resetPasswordFail: 'Password Changing Failed',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in CSA form page
     */
    public static CSA_FORM_MSGS = {
        csaFormStatusSuccess: 'Form status fetched successfully',
        csaFormStatusFail: 'Failed to fetch form status',
        tempSaverFail: 'Failed to save form information',
        tempSaverSuccess: 'CSA form saved successfully',
        formSubmitFail: 'Failed to submit CSA form information',
        formSubmitSuccess: 'CSA form submitted successfully',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in Patient my profile page
     */
    public static MY_PROFILE = {
        profileInfoFetchFail: 'Failed to fetch patient profile information'
    };


    public static TP_LOG = {
        logFetchFail: 'Failed to fetch log list'
    }

    public static TREATMENT_PLAN = {
        saveTreatmentPlan: 'Developmental Activity plan saved succssfully',
        activityFetchError: 'Oops! Failed to fetch the activity list',
        activityCreateSuccess: 'Activity created successfully',
        activityCreateError: 'Oops! Failed to create activity',
        activityUpdateSuccess: 'Activity updated successfully',
        activityUpdateError: 'Oops! Failed to update the activity',
        domainFetchError: 'Oops! Failed to fetch the domains list',
        activitySecSelected: 'Secondary matrix has been selected',
        activitySecRemoved: 'Secondary matrix has been removed',
    };


    public static MASTER_ACTIVITY = {
        deleteActivity: 'Activity deleted successfully',
        updateActivity: 'Activity updated successfully',
        createActivity: 'Activity created successfully',
        fetchListError: 'Failed to fetch activity list',
        deleteActivityFail: 'Failed to delete activity',
        updateActivityFail: 'Failed to update activity',
        createActivityFailed: 'Failed to create activity',
        fetchMatrixCOordinatesFail: 'Failed to fetch domains and sensors',
        activityTempSave: 'Developmental treatment activity info updated temporarily'
    };

    public static DATE_VALIDATIONS ={
        dateRangeValidation: 'End date should be greater than start date',
    }

    public static SETTINGS = {
        timeZoneSetSuccess: 'Tikmezone has been updated successfully',
        timeZoneSetSFail: 'Failed to update timezone',
    }

    public static DOWNLOAD = {
        downloadError: 'Download Failed',
    };


}
