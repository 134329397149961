import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDetails } from '@models/app-details';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AppConstants } from '@utilities/app-constants';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  // Constants
  appDetails: AppDetails;

  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
  ) {
    this.appDetails = this.appConfigService.getAppDetails();
  }

  ngOnInit(): void {
  }

  goToLoginPage(){
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

}
