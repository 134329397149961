import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignupData, SignUpResponse } from '@models/sign-up';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient) { }

    // ---------------- API METHODS ----------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { SignupData } signupData
   * Description: Makes the post request with the input credentials
   */
  postSignUpInfo(requestData: SignupData): Observable<SignUpResponse> {
    const apiUrl = ApiUrls.AUTH_SERVICE.signup;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: SignUpResponse) => {
          return response;
        }
      ),
    );
  }
}
