import { LocalStorageKeywords } from '@models/local-storage-keywords';

export class AuthConstants {

    public static mainRoleKeywords = {
        client: 'patient'
    };

    public static userRoleIdentifiers = {
        client: 'patient'
    };

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Stores the keywords used to store values in memory
     */
    public static localStorageVariables: LocalStorageKeywords = {
        access_token: 'access_token',
        refresh_token: 'refresh_token',
        tokenType: 'Bearer ',
        reference: 'reference',
        referenceId: 'referenceId',
        patientId: 'patientId',
        paymentId: 'paymentId',
    };

}
