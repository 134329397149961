<div class="modal-header">
  <h5 class="modal-title pull-left">{{this.pageTitle}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="login-form"
    [formGroup]="changePasswordForm"
    (ngSubmit)="onChangePassword()">
    <div class="row">
        <!-- Old Password -->
        <div class="col-12 mb-2">
            <label class="label form-label">Old Password</label>
            <div class="input-group">
                <input class="form-control field-input"
                formControlName="old_password"
                placeholder="Enter password"
                [type]="showPassword ? 'text': 'password'"
                [maxLength]="fieldValidations.passwordMax">
                <div class="input-group-append">
                <span class="input-group-text trans-bgd">
                    <i
                    [title]="showPassword ? 'Hide Password': 'ShowPassword'"
                    class="icon eye-icon"
                    [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="togglePasswordVisibility()">
                </i>
                </span>
                </div>
            </div>
            <!-- Error Messages -->
            <div class="form-error-con"
            *ngIf=" isFormSubmitted && changePasswordForm?.controls?.old_password?.errors?.required &&
                (isFormSubmitted || changePasswordForm.dirty || changePasswordForm.touched )">
                <span class="err-msg">
                {{errMsgs.mandarotyFields}}
                </span>
            </div>
            <div class="form-error-con"
                *ngIf="
                isFormSubmitted &&
                !changePasswordForm?.controls?.old_password?.errors?.required &&
                changePasswordForm?.controls?.old_password?.errors?.requiredPattern &&
                (isFormSubmitted || changePasswordForm?.dirty || changePasswordForm?.touched )">
                <span class="err-msg">
                {{errMsgs.passwordPattern}}
                </span>
            </div>
        </div>
        <!-- New Password -->
        <div class="col-12 mb-2">
            <label class="label form-label">New Password
              <i class="fa colored fa-question-circle" aria-hidden="true" title="View Password Policy"
              [popover]="passwordPolicyPopover" #resetPasswordPopover="bs-popover" placement="bottom" [outsideClick]="true"></i>
            </label>
            <div class="input-group">
                <input class="form-control field-input"
                formControlName="new_password"
                placeholder="Enter password"
                [type]="showPassword ? 'text': 'password'"
                [maxLength]="fieldValidations.passwordMax">
                <div class="input-group-append">
                <span class="input-group-text trans-bgd">
                    <i
                    [title]="showPassword ? 'Hide Password': 'ShowPassword'"
                    class="icon eye-icon"
                    [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="togglePasswordVisibility()">
                </i>
                </span>
                </div>
            </div>
            <!-- Error Messages -->
            <div class="form-error-con"
            *ngIf=" isFormSubmitted && changePasswordForm?.controls?.new_password?.errors?.required &&
                (isFormSubmitted || changePasswordForm.dirty || changePasswordForm.touched )">
                <span class="err-msg">
                {{errMsgs.mandarotyFields}}
                </span>
            </div>
            <div class="form-error-con"
                *ngIf="
                isFormSubmitted &&
                !changePasswordForm?.controls?.new_password?.errors?.required &&
                changePasswordForm?.controls?.new_password?.errors?.requiredPattern &&
                (isFormSubmitted || changePasswordForm?.dirty || changePasswordForm?.touched )">
                <span class="err-msg">
                {{errMsgs.passwordPattern}}
                </span>
            </div>
        </div>
        <!-- Confirm Password -->
        <div class="col-12 mb-2">
            <label class="label form-label">Confirm Password</label>
            <div class="input-group">
                <input
                    class="form-control field-input"
                    formControlName="confirm_password"
                    placeholder="Confirm password"
                    [type]="showConfirmPassword ? 'text': 'password'"
                    [maxLength]="fieldValidations.passwordMax">
                <div class="input-group-append">
                <span class="input-group-text trans-bgd">
                    <i
                    [title]="showConfirmPassword ? 'Hide Password': 'ShowPassword'"
                    class="icon eye-icon"
                    [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="toggleConfirmPasswordVisibility()">
                </i>
                </span>
                </div>
            </div>
            <!-- Error Messages -->
            <div class="form-error-con"
            *ngIf=" isFormSubmitted && changePasswordForm?.controls?.confirm_password?.errors?.required &&
                (isFormSubmitted || changePasswordForm.dirty || changePasswordForm.touched )">
                <span class="err-msg">
                {{errMsgs.mandarotyFields}}
                </span>
            </div>
            <div class="form-error-con"
                *ngIf=" isFormSubmitted &&
                !changePasswordForm?.controls?.confirm_password?.errors?.required &&
                changePasswordForm?.controls?.confirm_password?.errors?.requiredPattern &&
                (isFormSubmitted || changePasswordForm?.dirty || changePasswordForm?.touched )">
            <span class="err-msg">
            {{errMsgs.passwordPattern}}
            </span>
        </div>
        </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="btn-con">
    <button
      class="btn submit-btn"
      (click)="onChangePassword()">Confirm
    </button>
    <button
      class="btn cancel-btn"
      (click)="onCancel()">Close
    </button>
  </div>
</div>

<!-- PASSWORD POLICY POPOVER -->
<ng-template #passwordPolicyPopover>
  <div class="popover-title  popover-header">
    <div class="header-password">
      <div class="password-title">
        <span>Password Policy</span>
      </div>
      <div class="close-button">
        <button type="button" class="close " aria-label="Close" title="Close">
        <span class="close-span igm_handcursor" aria-hidden="true"  (click)="resetPasswordPopover.hide()">&times;</span>
      </button>
      </div>
    </div>
  </div>
  <div class="password-help">
    <p> Please enter password with the following criteria </p>
    <ul>
      <li>Password must contain atleast 8 characters.</li>
      <li>Maximum password length is 30 characters.</li>
      <li>Password must contain one capital letter(A-Z).</li>
      <li>Password must contain one small letter(a-z).</li>
      <li>Password must contain one number(0-9).</li>
      <li
      [innerHtml]="'Password must contain alteast one special character from the following !#$%&()*+,-./:;=?@[\]^_`{|}~.'"
      ></li>
    </ul>
  </div>
</ng-template>
