import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { MenuItem } from '@models/menu-item';
import { AppDetails } from '@models/app-details';
import { LoggedUserInfo } from '@models/logged-user';
import { ConfirmDialog } from '@models/confirm-dialog';

import { ConfirmDialogComponent } from '@shared-components/confirm-dialog/confirm-dialog.component';

import { MenuList } from '@utilities/menu-list';
import { AppConstants } from '@utilities/app-constants';
import { AuthConstants } from '@utilities/auth-constants';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AppConfigService } from '@services/app-config/app-config.service';
import { LayoutManagementService } from '@services/layout-manager/layout-management.service';
import { LayoutManager } from '@models/layout-manager';
import { SettingsComponent } from '@modules/settings/settings.component';
import { CsaFormConfig } from '@modules/csa-form/csa-form-config';
import { ChangePasswordComponent } from '@modules/auth/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // Constants
  dummyImgUrl: string;

  // App Info
  appDetails: AppDetails;

  // User Info
  userInfo: LoggedUserInfo;

  // Menu Info
  menuList: MenuItem[];

  // ModalRefs
  logoutModalRef: BsModalRef;
  settingsModalRef: BsModalRef;
  changePasswordModalRef: BsModalRef;

  // Subscriptions
  userInfoSubscription$ = new Subscription();
  layoutInfoSubscription$ = new Subscription();

  // Flags
  showSideBar: boolean;
  showNavBar: boolean;

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    private appConfigService: AppConfigService,
    private alertMsgService: AlertMsgService,
    private layoutManagementService: LayoutManagementService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.menuList = [];
    this.showSideBar = false
    this.showNavBar = false;
    this.appDetails = this.appConfigService.getAppDetails();
    this.dummyImgUrl = 'assets/images/avatar-default.png';
    this.subscribeToUserInfo();
    this.subscribeLayoutInfo();
  }

  ngOnDestroy() {
    this.userInfoSubscription$.unsubscribe();
    this.layoutInfoSubscription$.unsubscribe();
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Populates user info in the header section
   */
  subscribeToUserInfo() {
    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (user: LoggedUserInfo) => {
        this.userInfo = user;
        if ((this.userInfo.applnStatus !== CsaFormConfig.FORM_STATUS.default) && (this.userInfo.applnStatus !== CsaFormConfig.FORM_STATUS.inProgress) && this.userInfo.applnStatus !== undefined) {
          this.setMenuList();
        }
      }
    );
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Manages layout of header
   */
  subscribeLayoutInfo() {
    this.layoutInfoSubscription$ = this.layoutManagementService.layoutManager.subscribe(
      (layoutInfo: LayoutManager) => {
        if (layoutInfo) {
          this.showNavBar = layoutInfo.showHeaderNav;
        }
      }
    );
  }

  ngOnInit(): void { }

  setMenuList() {
    // this.menuList = MenuList.paidClientMenu;
    if (this.userInfo.dhs) {
      this.menuList = MenuList.dhsClientMenu;
    }
    else{
      this.menuList = MenuList.paidClientMenu;
    }
  }

  handleBrokenImage(image) {
    this.userInfo.avatar = this.dummyImgUrl;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Shows a confirmation dialog asking whether the user is sure to logout
   */
  onLogoutClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.logoutModalRef = this.modalService.show(
      ConfirmDialogComponent,
      {
        class: 'modal-dialog-centered modal-sm',
        initialState
      }
    );
    this.logoutModalRef.content.onConfirmProcess.subscribe(
      (result) => {
        if (result) {
          this.logoutUser();
        }
      }
    );
  }

  logoutUser() {
    this.authService.logoutUser().subscribe(
      (response) => { },
      (error: HttpErrorResponse) => { }
    );
  }

  toggleSideBar() {
    this.showSideBar = !this.showSideBar;
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Shows a dialog box of site settings
   */
  onSettingsClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.settingsModalRef = this.modalService.show(
      SettingsComponent,
      {
        class: 'modal-dialog-centered modal-md',
        initialState
      }
    );
  }

  onChangePasswordClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.changePasswordModalRef = this.modalService.show(
      ChangePasswordComponent,
      {
        class: 'modal-dialog-centered modal-md',
        initialState
      }
    );
  }

}
