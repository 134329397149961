import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { NoAuthGuard } from '@guards/no-auth/no-auth.guard';
import { RoleGuard } from '@guards/role-guard/role.guard';

import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from '@layout/content-layout/content-layout.component';

import { AuthConstants } from '@utilities/auth-constants';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [RoleGuard],
    component: ContentLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'profile',
        data: {
          checkFormStatus: true,
          expectedRole: [
            AuthConstants.userRoleIdentifiers.client,
        ],},
        loadChildren: () =>
          import('@modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'treatment-plan',
        data: {
          checkFormStatus: true,
          expectedRole: [
            AuthConstants.userRoleIdentifiers.client,
        ],},
        loadChildren: () =>
          import('@modules/treatment-plan-detail/treatment-plan-detail.module').then(m => m.TreatmentPlanDetailModule)
      },
      {
        path: 'appointments',
        loadChildren: () =>
          import('@modules/appointments/appointments.module').then(m => m.AppointmentsModule)
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('@modules/invoices/invoices.module').then(m => m.InvoicesModule)
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('@modules/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'csa-form',
        loadChildren: () => import('./modules/csa-form/csa-form.module').then(m => m.CsaFormModule)
      },
      {
        path: 'appointment-details/:id',
        loadChildren: () =>
          import('@modules/appointment-details/appointment-details.module').then(m => m.AppointmentDetailsModule)
      },
      //------------------------------------------------------------
       {
        path: 'activitycalender',
        loadChildren: () =>
          import('@modules/activitylist/activitylist.module').then(m => m.ActivitylistModule)
      },
    ]
  },
  // Fallback when no prior routes is matched
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
