import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { ForgotPasswordData, ForgotPasswordResponse } from '@models/forgot-password';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { requiredPattern } from '@utilities/validators';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  // Constants
  errMsgs: any;
  redirectUrls: any;
  pageTitle: string;
  fieldValidations: any;
  generalValidations: any;

  // Form related variables
  forgotPasswordForm: FormGroup;

  // Flags
  isFormSubmitted: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private forgotPasswordService: ForgotPasswordService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.isFormSubmitted = false;
    this.redirectUrls = AppConstants.redirectUrls;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;
    this.pageTitle = AppConstants.pageTitles.forgotPassword;
    this.generalValidations = InputFieldLimits.generalValidations;
   }

  ngOnInit(): void {
    this.initialiseForgotPasswordForm();
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Initialises forgotpassword form with necessary form fields
   */
  initialiseForgotPasswordForm() {
    this.authService.purgeAuth();
    localStorage.clear();
    this.sessionStorageService.clearLocalStorageValues();
    this.forgotPasswordForm = this.fb.group({
        email: ['', [Validators.required, requiredPattern(this.generalValidations.emailRegx),]],
      },
    );
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Validates the form and calls the post API method based on the result
   */
  onResetPassword() {
    this.isFormSubmitted = true;
    if (this.forgotPasswordForm.valid) {
      const ForgotPasswordData: ForgotPasswordData = this.forgotPasswordForm.value;
      this.postForgotPasswordInfo(ForgotPasswordData);
    }
  }

  /**
   * Author: Jeswin Chacko P A
   * @param { LoginInfo } ForgotPasswordData
   * Description: Send the forgot-password info to the  API
   */
  postForgotPasswordInfo(ForgotPasswordData: ForgotPasswordData) {
    let requestData: ForgotPasswordData = this.constructRequestData(ForgotPasswordData);
    this.forgotPasswordService.postForgotPasswordInfo(requestData).subscribe(
      (response: ForgotPasswordResponse) => {
        if (response.status) {
            this.utilityService.showResponseSuccess(ToastrMessages.FORGOTPASSWORD_FORM_MSGS.forgotPasswordSuccess, response.message);
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
        } else {
          this.utilityService.showResponseError(ToastrMessages.FORGOTPASSWORD_FORM_MSGS.forgotPasswordFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.FORGOTPASSWORD_FORM_MSGS.forgotPasswordFail, error.error.message );
        }
    }
    );
  }

  /**
    * Author: Jeswin Chacko P A
    * Description: COnstructs requestdata for forgot-password API request
    */
  constructRequestData(ForgotPasswordData: ForgotPasswordData): ForgotPasswordData {
    ForgotPasswordData.role = "patient";
    ForgotPasswordData.organisation = "apollo";
    const requestData: ForgotPasswordData = ForgotPasswordData;
    return requestData;
  }

  onCancelClick() {
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

}
