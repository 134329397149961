import { Injectable } from '@angular/core';
import { AlertMsg } from '@models/alert-msgs';
import { ToastrMessages } from '@utilities/toastr-messages';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertMsgService {

  alertMsg: string;
  msgTitle: string;

  constructor(
    private toastr: ToastrService
  ) {
    this.alertMsg = '';
    this.msgTitle = '';
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Shows an error alert message
   */
  public showErrorToster(alertMsg: AlertMsg) {
    this.msgTitle = alertMsg.title ? alertMsg.title : ToastrMessages.TOASTR_TITLES.error;
    this.toastr.error(alertMsg.msg, this.msgTitle);
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Shows a warning altert message
   */
  public showWarningToster(alertMsg: AlertMsg) {
    this.msgTitle = alertMsg.title ? alertMsg.title : ToastrMessages.TOASTR_TITLES.warning;
    this.toastr.warning(alertMsg.msg, this.msgTitle);
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Shows a success altert message
   */
  public showSuccessToster(alertMsg: AlertMsg) {
    this.msgTitle = alertMsg.title ? alertMsg.title : ToastrMessages.TOASTR_TITLES.success;
    this.toastr.success(alertMsg.msg, this.msgTitle);
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Shows a info altert message
   */
  public showInfoToastr(alertMsg: AlertMsg) {
    this.msgTitle = alertMsg.title ? alertMsg.title : ToastrMessages.TOASTR_TITLES.info;
    this.toastr.info(alertMsg.msg, this.msgTitle);
  }
}
