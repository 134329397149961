// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // authApiUrl: 'https://stage-shareddd.guardianrpm.com/',
  // featureApiUrl: 'https://stage-clientdd.guardianrpm.com/',
  // commonApiUrl: 'https://stage-caredd.guardianrpm.com/',
  // cpApiUrl: 'https://stage-caredd.guardianrpm.com/',

  authApiUrl: 'https://shared-api-stage.guardianrpm.com/',
  featureApiUrl: 'https://client-api-stage.guardianrpm.com/',
  commonApiUrl: 'https://care-api-stage.guardianrpm.com/',
  cpApiUrl: 'https://care-api-stage.guardianrpm.com/',


  paymentGatewayUrl: 'https://test.authorize.net/payment/payment',
};


/* export const environment = {
  production: false,
  cpApiUrl: 'http://18.219.52.202:8082/',
  authApiUrl: 'http://18.219.52.202:8080/',
  featureApiUrl: 'http://18.219.52.202:8081/',
  commonApiUrl: 'http://18.219.52.202:8082/',
  paymentGatewayUrl: 'https://test.authorize.net/payment/payment',
}; */

