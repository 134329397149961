import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ApiUrls } from '@utilities/api-urls';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';

import { CsaFormConfig } from './csa-form-config';

import {
  ClientInfo,
  ConsolidatedFormInfo,
  CsaFormInfo,
  CsaFormStatusResponse,
  FinalFormInfoRequestData,
  FormFinalSaveResponse,
  FormInfo,
  FormTempSaveResponse,
  LiabilityFormMaker,
  TempFormInfoRequestData
} from '@models/csa-form-generics';
import { BackNavigationConfig, CountryListResponse } from '@models/generic-types';

import { AuthService } from '@services/auth/auth.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { UploadParams } from '@models/file-upload';

@Injectable({
  providedIn: 'root'
})
export class CsaFormService {

  // Constants
  stepsList: CsaFormInfo[];

  // viewMode flags
  isCSAViewMode: boolean;
  isActiveFormViewMode: boolean;

  uploadParams: UploadParams;

  activeStepInfo: CsaFormInfo;
  clientDetailInfo: ClientInfo;
  consolidatedCsaFormInfo: ConsolidatedFormInfo;
  stepChangeSubject = new Subject<CsaFormInfo>();
  formSubmitSubject = new Subject<CsaFormInfo>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.isCSAViewMode = false;
    this.isActiveFormViewMode = false;
    this.stepsList = CsaFormConfig.FORM_STEPS_DETAILS;
  }

  // -------------- FORM NAVIGATION METHODS -------------

  setActiveStepData(currentStepInfo: any) {
    this.activeStepInfo = currentStepInfo;
    this.stepChangeSubject.next(this.activeStepInfo)
  }

  triggerFormSubmitClick(formInfo: CsaFormInfo) {
    if (this.isActiveFormViewMode) {
      this.redirectToNextForm();
    } else {
      this.formSubmitSubject.next(formInfo);
    }
  }

  redirectToNextForm() {
    let routeUrl: string = '';
    const currentIndex = this.activeStepInfo.stepIndex;
    const nextIndex = currentIndex + 1;
    const nextStepDetails: CsaFormInfo = this.stepsList.find((stepInfo: CsaFormInfo) => stepInfo.stepIndex == nextIndex);
    routeUrl = nextStepDetails ? nextStepDetails.url : 'csa-form/intro';
    this.router.navigateByUrl(routeUrl);
  }

  getBackBtnConfig(): BackNavigationConfig {
    let config: BackNavigationConfig = CsaFormConfig.GO_TO_PROFILE_CONIFG;
    if(this.isCSAViewMode) {
      config.enable = true;
    }
    return config;
  }

  // ---------------- FORM VALUE SET METHODS ----------------

  getStepBasedFormInfo(selectedFormPageNo: any): FormInfo {
    if (this.consolidatedCsaFormInfo && this.consolidatedCsaFormInfo.finalForms) {
      return this.consolidatedCsaFormInfo.finalForms.find((formVal: FormInfo) => formVal.formPageNo == selectedFormPageNo);
    }
  }

  updateConsolidatedFormInfo(savedFormDetails: any) {
    if (this.consolidatedCsaFormInfo.finalForms.length > 0) {
      const selectedFormIndex = this.utilityService.findItemIndex(
        this.consolidatedCsaFormInfo.finalForms,
        savedFormDetails.data.formPageNo,
        'formPageNo');
      const formInfo = savedFormDetails.data.formInfo;
      const updatedFormInfo: FormInfo = {
        formPageNo: savedFormDetails.data.formPageNo,
        formInfo: formInfo,
      };
      if (selectedFormIndex != -1) {
        this.consolidatedCsaFormInfo.finalForms[selectedFormIndex] = JSON.parse(JSON.stringify(updatedFormInfo));
      } else {
        this.consolidatedCsaFormInfo.finalForms.push(JSON.parse(JSON.stringify(updatedFormInfo)));
      }
    } else {
      this.fetchCsaTempFormStatus().subscribe(
        (response: CsaFormStatusResponse) => {
          if (response.status) {
            this.consolidatedCsaFormInfo = response.data;
          }
        }
      );
    }
  }

  setFileUploadParams() {
    this.uploadParams = {
      reference: CsaFormConfig.FILE_UPLOAD_VAL.reference,
      referenceid: this.clientDetailInfo._id,
      u_code: CsaFormConfig.FILE_UPLOAD_VAL.u_code
    };
    return this.uploadParams;
  }

  // ---------- REQUEST DATA FORMATTING METHODS -----------

  constructRequestData(formInfo: any): TempFormInfoRequestData {
    const requestData: TempFormInfoRequestData = {
      patientId: this.sessionStorageService.getPatientIdFromStorage(),
      formPageNo: this.activeStepInfo.formPageNo,
      formInfo: formInfo,
    };
    return requestData;
  }

  constrcutFormConfig(): ConsolidatedFormInfo {
    const consolidatedInfo: ConsolidatedFormInfo = {
      id: '',
      patientId: this.sessionStorageService.getPatientIdFromStorage(),
      is_deleted: false,
      finalForms: [],
      liabilityFormInfo: LiabilityFormMaker.create()
    };
    return consolidatedInfo;
  }

  // ---------------- HTTP REQUESTS METHODS ---------------

  fetchCsaTempFormStatus(requestData?: any): Observable<CsaFormStatusResponse> {
    const patientId = this.sessionStorageService.getPatientIdFromStorage();
    const apiUrl = `${ApiUrls.CSA_FORM.getTempFormInfo}${patientId}/get`;
    const paramsObj = {
      params: requestData
    };
    return this.http.get(apiUrl).pipe(
      map (
        (response: CsaFormStatusResponse) => {
          if (response.data) {
            this.consolidatedCsaFormInfo = response.data;
          } else {
            this.consolidatedCsaFormInfo = this.constrcutFormConfig();
          }
          return response;
        },
      ),
    );
  }

  fetchCsaFinalFormStatus(requestData?: any): Observable<CsaFormStatusResponse> {
    const patientId = this.sessionStorageService.getPatientIdFromStorage();
    const apiUrl = `${ApiUrls.CSA_FORM.getFinalFormInfo}${patientId}/get`;
    const paramsObj = {
      params: requestData
    };
    return this.http.get(apiUrl).pipe(
      map (
        (response: CsaFormStatusResponse) => {
          if (response.data) {
            this.consolidatedCsaFormInfo = response.data[0];
          } else {
            this.consolidatedCsaFormInfo = this.constrcutFormConfig();
          }
          return response;
        },
      ),
    );
  }

  fetchCountryList(): Observable<CountryListResponse> {
    const apiUrl = `${ApiUrls.CSA_FORM.getCountryList}`;
    return this.http.get(apiUrl).pipe(
      map (
        (response: any) => {
          return response;
        },
      ),
    );
  }

  fetchStatesList(countryId): Observable<any> {
    const apiUrl = `${ApiUrls.CSA_FORM.getCountryList}/${countryId}/${ApiUrls.CSA_FORM.getStatesList}`;
    return this.http.get(apiUrl).pipe(
      map (
        (response: any) => {
          return response;
        },
      ),
    );
  }

  tempPostFormData(requestData: TempFormInfoRequestData): Observable<FormTempSaveResponse> {
    console.info('TEMP SAVE INVOKED!', requestData.formPageNo);
    const apiUrl = ApiUrls.CSA_FORM.formTempSave;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: FormTempSaveResponse) => {
          if (response.status) {
            this.updateConsolidatedFormInfo(response);
            this.redirectToNextForm();
          } else {
            this.utilityService.showResponseError(ToastrMessages.CSA_FORM_MSGS.tempSaverFail, response.message);
          }
          return response;
        },
        (error: HttpErrorResponse) => {
          if (error) {
            this.utilityService.showResponseError(ToastrMessages.CSA_FORM_MSGS.tempSaverFail, error.error.message );
          }
        }
      ),
    );
  }

  checkLiabilityStatus() {
    const patientId = this.sessionStorageService.getPatientIdFromStorage();
    const apiUrl = `${ApiUrls.CSA_FORM.getLiabilityFormStatus}/${patientId}/get`;
    return this.http.get(apiUrl).pipe(
      map (
        (response: any) => {
          this.consolidatedCsaFormInfo = response.data;
          return response;
        },
      ),
    );
  }

  finalPostFormData(requestData: FinalFormInfoRequestData): Observable<FormFinalSaveResponse> {
    const apiUrl = ApiUrls.CSA_FORM.formFinalSubmit;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: FormFinalSaveResponse) => {
          if (response.status) {
            this.updateUserOverallInfo();
            this.utilityService.redirectToLandingPage();
          } else {
            this.utilityService.showResponseError(ToastrMessages.CSA_FORM_MSGS.formSubmitFail, response.message);
          }
          return response;
        },
        (error: HttpErrorResponse) => {
          if (error) {
            this.utilityService.showResponseError(ToastrMessages.CSA_FORM_MSGS.formSubmitFail, error.error.message );
          }
        }
      ),
    );
  }

  updateUserOverallInfo() {
    this.authService.fetchPersonalInfo().subscribe(
      (response) => {
      },
      (error) => {

      })
  }

}
