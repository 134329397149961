export class ApiUrls {

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores the api-urls common to all services
     */
    public static COMMON_SERVICE = {
      fileUpload: 'upload/'
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores the api-urls related to auth service
     */
    public static AUTH_SERVICE = {
      login: 'login/',
      logout: 'logout/',
      signup: 'signup/',
      refreshToken: 'token',
      userDetails: 'getLoggedinUser/',
      confirm_account: 'v1/signup/confirm',
      resetPassword: 'reset/password',
      forgotPassword: 'forgot/password',
      changePassword: 'change/password',
      updateSettings: 'update/settings',
    };

    /**
    * Author: Gokul Krishna
    * Description: Stores the api-urls related to appointments service
    */
    public static APPOINTMENTS_SERVICE = {
      getCalendarAppointments: 'getcalenderdates',
      getSchedules: 'getSchedules',
      getSession: 'getappointment/',
      cancelSession: 'telemedicine/'
    };

    public static SESSION_SERVICE = {
      start: 'startsession/',
      startRecord: 'archive/',
      stopRecord: 'archive/'
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores the api-urls related to csa form service
     */
    public static CSA_FORM = {
        formTempSave: 'savecsatmp',
        getTempFormInfo: 'getcsatmp/',
        getFinalFormInfo: 'getcsafinal/',
        getCountryList: 'country',
        getStatesList: 'states',
        formFinalSubmit: 'savecsafinal',
        getLiabilityFormStatus: 'getcsafinal',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores the api-urls related to profile form service
     */
    public static MY_PROFILE = {
        patientProfile: 'patientprofile'
    };

    public static EVALUATION_FEEDBACK_SERVICE = {
      getFeedbackQuestions: 'getfeedbackqns',
      postFeedback: 'submitfeedback',
    };

    public static TP_SCHEDULED_ACTIVITY_SERVICE = {
      getActivitySchedules: 'gettpcalenderdates',
      completeActivity:'completeactivity'
    };

    public static ACTIVITIES = {
      saveActivity: 'saveactivity',
      masterActivities: 'getactivitylist',
      matrixCoordinates: 'getdomainssensors'
    };

    public static INVOICES = {
      getinvoices: 'getinvoices',
      downloadInvoice: 'download/invoice',
    };

    public static PAYMENT = {
      getpaymentform: 'getpaymentform',
      updatepayment: 'updatepayment'
    };

    public static ACTIVITY_DETAIL = {
      getTpActivityList: 'gettpactivities'
    }

    public static TREATMENT_PLAN = {
      saveTp: 'savetp',
      gettpdetails: 'gettpdetails',
      tpChangeLog: 'gettpchangelog'
    };
}
