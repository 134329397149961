import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { AlertMsg } from '@models/alert-msgs';
import { SignInResponseData } from '@models/sign-in';
import { LoggedUserInfo } from '@models/logged-user';
import { TokenInfo } from '@models/basic-configuration';
import { LayoutManager } from '@models/layout-manager';
import { RefreshTokenRequest, RefreshTokenResponse } from '@models/token-auth';
import { ActivateAccountRequest, ActivateAccountResponse } from '@models/activate-account';

import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { LayoutManagementService } from '@services/layout-manager/layout-management.service';

import { ApiUrls } from '@utilities/api-urls';
import { AppConstants } from '@utilities/app-constants';
import { AuthConstants } from '@utilities/auth-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  dhsId: any;
  isDHSEnrolled: boolean;

  currentUserSubject = new BehaviorSubject<LoggedUserInfo>({} as LoggedUserInfo);
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private layoutManagementService: LayoutManagementService,
    private utilityService: UtilityService,
    private alertMessageService: AlertMsgService,
    private storageService: SessionStorageService
  ) {
    this.dhsId = '';
    this.isDHSEnrolled = false;
  }

  // ---------- AUTHORIZATION METHODS ------------

  setUserVariables(loginInfo: LoggedUserInfo) {
    this.setDHSStatus(loginInfo.dhs);
    this.setPatientIdInStorage(loginInfo.patientId);
    this.setUserTokenInStorage(loginInfo.token);
    this.triggerUserBasedSubjects(loginInfo);
  }

  isAuthenticated(): boolean {
    let retVal: boolean = false;
    if (this.utilityService.checkTokenInStorage()) {
      retVal = true;
    }
    return retVal;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Clears values stored in local storage
   */
  logoutUser(): Observable<any> {
    const apiUrl = ApiUrls.AUTH_SERVICE.logout;
    return this.http.post(apiUrl, {}).pipe(
      map (
        (data: any) => {
          if (data) {
            this.purgeAuth();
            const alert: AlertMsg = { msg: ToastrMessages.AUTH_MESSAGES.logoutSuccess, title:  '' };
            this.alertMessageService.showSuccessToster(alert)
            this.storageService.clearLocalStorageValues();
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
          } else {
            this.purgeAuth();
            this.storageService.clearLocalStorageValues();
            this.utilityService.showResponseError(ToastrMessages.AUTH_MESSAGES.logoutFail)
          }
          return data;
        },
        (error) => {
          this.purgeAuth();
          this.storageService.clearLocalStorageValues();
          this.utilityService.showResponseError(ToastrMessages.AUTH_MESSAGES.logoutFail)
          this.router.navigateByUrl(AppConstants.redirectUrls.login);
        }
      ));
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Resets the token if unauthenticated
   */
  purgeAuth() {
    this.storageService.clearLocalStorageValues();
    this.currentUserSubject.next({} as LoggedUserInfo);
  }

  // ---------------------------- PERMISSIONS & DETAILS --------------------

  fetchPersonalInfo(): Observable<any> {
    const apiUrl = ApiUrls.AUTH_SERVICE.userDetails;
    return this.http.get(apiUrl).pipe(
      map (
        (response: SignInResponseData) => {
          this.setDHSStatus(response.data.dhs);
          const layoutInfo: LayoutManager = {
            showHeaderNav: this.utilityService.checkFormStatus(response.data.applnStatus)
          };
          this.layoutManagementService.triggerLayoutSubjects(layoutInfo);
          this.setPatientIdInStorage(response.data.patientId);
          this.triggerUserBasedSubjects(response.data);
          return response;
        },
      ),
    );
  }

  // ------------------ SUBJECT RELATED VARIABLES --------------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Sets the user unique token in local storage
   */
  setUserTokenInStorage(tokenInfo: TokenInfo) {
    // Access Token
    localStorage.setItem(
      AuthConstants.localStorageVariables.access_token,
      `${AuthConstants.localStorageVariables.tokenType}${tokenInfo.access_token}`);
  }

  setDHSStatus(dhsVal: any) {
    if (dhsVal) {
      this.dhsId = dhsVal;
      this.isDHSEnrolled = true;
    } else {
      this.dhsId = '';
      this.isDHSEnrolled = false;
    }
  }

  setPatientIdInStorage(patientId: string) {
    // PatientId
    localStorage.setItem(AuthConstants.localStorageVariables.patientId, patientId);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { loggedUserInfo } userData
   * Description: Sets the user unique token in local storage
   */
  triggerUserBasedSubjects(userData: LoggedUserInfo) {
    this.currentUserSubject.next(userData);
  }

  // ------------------ HTTP REQUESTS ---------------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { ActivateAccountRequest }
   * Description: Makes the post request with the user key
   */
  activateUserAccount(requestData: ActivateAccountRequest): Observable<ActivateAccountResponse> {
    const apiUrl = `${ApiUrls.AUTH_SERVICE.confirm_account}`;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: ActivateAccountResponse) => {
          return response;
        }
      ),
    );
  }

}
