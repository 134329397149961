import { MenuItem } from '@models/menu-item';

export class MenuList {

  public static paidClientMenu: MenuItem[] = [
    {
      id: 1,
      key: 'profile',
      link: '/profile',
      title: 'My Profile',
      showOnList: true
    },
    {
        id: 2,
        key: 'appointments',
        link: '/appointments',
        title: 'Appointments',
        showOnList: true
    },
    {
      id: 3,
      key: 'invoices',
      link: '/invoices',
      title: 'Invoices',
      showOnList: true
    },
  ];

  public static dhsClientMenu: MenuItem[] = [
    {
      id: 1,
      key: 'profile',
      link: '/profile',
      title: 'My Profile',
      showOnList: true
    },
    {
        id: 2,
        key: 'appointments',
        link: '/appointments',
        title: 'Appointments',
        showOnList: true
    },
  ];

}
