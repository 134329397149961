<!-- Footer -->
<footer class="sticky-footer bg-white">
    <div class="container my-auto">
        <div class="copyright text-center my-auto">
        <span>
            Copyright &copy;
            {{footerText}}  
             {{currentYear}}</span>
        </div>
    </div>
</footer>
<!-- End of Footer -->