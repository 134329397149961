import { Injectable } from '@angular/core';
import { AppDetails } from '@models/app-details';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }

  getAppDetails ():  AppDetails{
    const appDetails: AppDetails = {
      appName: 'Guardian RPM USA'
    }
    return appDetails;
  }

}
