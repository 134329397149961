import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupData, SignUpResponse } from '@models/sign-up';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AuthService } from '@services/auth/auth.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { requiredPattern } from '@utilities/validators';
import { SignUpService } from './sign-up.service';

import { PopoverConfig, PopoverDirective } from 'ngx-bootstrap/popover';

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    container: 'body',
  });
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {


  // Constants
  errMsgs: any;
  redirectUrls: any;
  pageTitle: string;
  fieldValidations: any;
  generalValidations: any;

  // Form related variables
  signUpForm: FormGroup;

  // Flags
  showPassword: boolean;
  isFormSubmitted: boolean;
  showConfirmPassword: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private signUpService: SignUpService,
    private utilityService: UtilityService,
    private alertMessageService: AlertMsgService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.showPassword = false;
    this.showConfirmPassword = false;
    this.isFormSubmitted = false;
    this.redirectUrls = AppConstants.redirectUrls;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;
    this.pageTitle = AppConstants.pageTitles.signUp;
    this.fieldValidations = InputFieldLimits.signupForm;
    this.generalValidations = InputFieldLimits.generalValidations;
   }

  ngOnInit(): void {
    this.initialiseSignupForm();
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Initialises signup form with necessary form fields
   */
  initialiseSignupForm() {
    this.authService.purgeAuth();
    localStorage.clear();
    this.sessionStorageService.clearLocalStorageValues();
    this.signUpForm = this.fb.group({
        fname: ['', [Validators.required]],
        mname: ['', []],
        lname: ['', [Validators.required]],
        emailID: ['', [Validators.required, requiredPattern(this.generalValidations.emailRegx),]],
        phone: ['', [Validators.required]],
        password1: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
        password2: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
      },
    );
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Validates the form and calls the post API method based on the result
   */
  onCreateAccount() {
    this.isFormSubmitted = true;
    if (this.signUpForm.valid) {
      const SignupData: SignupData = this.signUpForm.value;
      this.postSignUpInfo(SignupData);
    }
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { LoginInfo } SignupData
   * Description: Send the login info to the  API
   */
  postSignUpInfo(SignupData: SignupData) {
    let requestData: SignupData = this.constructRequestData(SignupData);
    this.signUpService.postSignUpInfo(requestData).subscribe(
      (response: SignUpResponse) => {
        if (response.status) {
            this.utilityService.showResponseSuccess(ToastrMessages.SIGNUP_FORM_MSGS.signupSuccess, response.message);
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
        } else {
          this.utilityService.showResponseError(ToastrMessages.SIGNUP_FORM_MSGS.signupFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.SIGNUP_FORM_MSGS.signupFail, error.error.message );
        }
    }
    );
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: COnstructs requestdata for login API request
    */
  constructRequestData(SignupData: SignupData): SignupData {
    SignupData.password1 = this.utilityService.hashPassword(SignupData.password1);
    SignupData.password2 = this.utilityService.hashPassword(SignupData.password2);
    const requestData: SignupData = SignupData;
    return requestData;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Toggles the flag which controls password visibility
   */
  togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Toggles the flag which controls confirm password visibility
   */
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onCancelClick() {
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Resets the login form
   */
  resetsignUpForm() {
    this.signUpForm.reset();
  }

}
