import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SignInInfo, SignInResponseData } from '@models/sign-in';

import { ApiUrls } from '@utilities/api-urls';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    ) { }

  // ---------------- API METHODS ----------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { SignInInfo } loginData
   * Description: Makes the post request with the input credentials
   */
  postLoginInfo(requestData: SignInInfo): Observable<SignInResponseData> {
    const apiUrl = ApiUrls.AUTH_SERVICE.login;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: SignInResponseData) => {
          this.authService.setUserVariables(response.data);
          return response;
        }
      ),
    );
  }

}
