import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsRequestData, SettingsResponse } from '@models/settings';
import { CsaFormService } from '@modules/csa-form/csa-form.service';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private csaService: CsaFormService,
    private http: HttpClient
    ) { }

  // ---------------- API METHODS ----------------

/**
 * Author: Jeswin Chacko P A
 * @param { ForgotPasswordData } ForgotPasswordData
 * Description: Makes the post request with the input credentials
 */
updateTimeZone(requestData: SettingsRequestData): Observable<SettingsResponse> {
  const apiUrl = ApiUrls.AUTH_SERVICE.updateSettings;
  return this.http.put(apiUrl, requestData).pipe(
    map (
      (response: SettingsResponse) => {
        this.csaService.updateUserOverallInfo();
        return response;
      }
    ),
  );
}
}
