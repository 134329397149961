import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthService } from '@services/auth/auth.service';
import { LoaderService } from '@services/loader/loader.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';

import { AppConstants } from '@utilities/app-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { ThirdPartyConfig } from '@utilities/third-party-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  // Subscriptions
  authSubscription$: Subscription;
  routerEventsSubscriptions: Subscription;

  // Flags
  checkAuthencation: boolean;

  tokenValue: string;

  // Configurations
  spinnerConfig: any;
  loadingBarConfig: any;

  constructor (
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.tokenValue = '';
    this.checkAuthencation = false;
    this.authSubscription$ = new Subscription();
    this.routerEventsSubscriptions = new Subscription();
    this.spinnerConfig = ThirdPartyConfig.spinnerConfig;
    this.loadingBarConfig = ThirdPartyConfig.loadingBarConfig;
    this.subscribeToRouterEvents();
    this.checkTokenInStorage();
    this.authenticateUser();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.authSubscription$.unsubscribe();
    this.routerEventsSubscriptions.unsubscribe();
  }

  /** 
  * Author: T0512 Krupa Tresa Joseph
  * Description: Check the value of token in storage
  */
  checkTokenInStorage() {
  if (this.utilityService.checkTokenInStorage()) {
      this.tokenValue = this.sessionStorageService.getTokenValueFromStorage();
      this.checkAuthencation = true;
  }
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Each time when the appis refreshed, the authchecking is done,
   * based on the presence of the token in the local storage 
   */
  authenticateUser() {
    if (this.checkAuthencation) {
      this.authService.fetchPersonalInfo().subscribe(
        (response) => {
        },
        (error: HttpErrorResponse) => {
          this.authService.purgeAuth();
          this.utilityService.showResponseError(ToastrMessages.AUTH_MESSAGES.userInfoFailFetch );
          this.router.navigateByUrl(AppConstants.redirectUrls.login);
        }
      );
    }
  }

  subscribeToRouterEvents() {
    this.router.events.subscribe(
      (event : RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.loaderService.showSpinner();
        }
        if ((event instanceof NavigationEnd) || (event instanceof NavigationCancel) || (event instanceof NavigationError)) {
          this.loaderService.hideSpinner();
        }
      }
    );
  }

}
