import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPasswordData, ResetPasswordResponse } from '@models/reset-password';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http: HttpClient) { }

    // ---------------- API METHODS ----------------

  /**
   * Author: Jeswin Chacko P A
   * @param { ResetPasswordData } ResetPasswordData
   * Description: Makes the post request with the input credentials
   */
  postResetPasswordInfo(requestData: ResetPasswordData): Observable<ResetPasswordResponse> {
    const apiUrl = ApiUrls.AUTH_SERVICE.resetPassword;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: ResetPasswordResponse) => {
          return response;
        }
      ),
    );
  }
}
