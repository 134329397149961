import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NgxMaskModule } from 'ngx-mask';
import { ThirdPartyConfig } from '@utilities/third-party-config';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    NgxMaskModule.forRoot(ThirdPartyConfig.inputMaskConfig),
  ]
})
export class AuthModule { }
