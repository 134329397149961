<div class="container-wrap">

  <!-- TITLE -->
  <div class="page-info">
      <h3 class="title page-title">{{pageTitle}}</h3>
  </div>

  <!-- FORM -->
  <div class="container">
      <form class="login-form"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="onResetPassword()">
          <div class="row">
              <!-- Email ID -->
              <div class="col-12 mb-2">
                  <label class="label form-label">Email ID</label>
                  <input
                      type="text"
                      class="form-control field-input"
                      placeholder="Enter email"
                  formControlName="email">
                  <!-- Error Messages - Mandatory field -->
                  <div
                      class="form-error-con"
                      *ngIf=" isFormSubmitted && forgotPasswordForm?.controls?.email?.errors?.required &&
                      (isFormSubmitted || forgotPasswordForm.dirty || forgotPasswordForm.touched )">
                  <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                  </div>
                  <!-- Error Messages - Email format -->
                  <div
                      class="form-error-con"
                      *ngIf="isFormSubmitted && forgotPasswordForm?.controls?.email?.errors?.requiredPattern &&
                      forgotPasswordForm?.value.email && (forgotPasswordForm?.dirty || forgotPasswordForm?.touched )">
                      <span class="err-msg">{{errMsgs?.invalidEmail}}</span>
                  </div>
              </div>
              <!-- Buttons -->
              <div class="col-12 mb-2">
                  <div class="field-con btn-con">
                      <button
                          class="btn cancel-btn"
                          type="submit"
                          (click)="onCancelClick()"
                          title="Cancel">Cancel
                      </button>
                      <button
                      class="btn submit-btn login-btn"
                      type="submit"
                      title="Reset Password">Reset Password
                      </button>
                  </div>
              </div>
          </div>
      </form>
  </div>

</div>
