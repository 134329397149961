import { Component, OnInit } from '@angular/core';
import { AppConstants } from '@utilities/app-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  // Date Info
  currentDate;
  currentYear;

  // Footer text content
  footerText: string;

  constructor() {
    this.currentDate = new Date();
    this.footerText = AppConstants.footerContent.textContent;
   }

  ngOnInit() {
    this.setCurrentYear();
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Set current year information on footer 
  */
  setCurrentYear() {
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
  }


}
