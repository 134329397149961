import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordData, ChangePasswordResponse } from '@models/change-password';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http: HttpClient) { }

  /**
   * Author: Jeswin Chacko P A
   * @param { ResetPasswordData } ChangePasswordData
   * Description: Makes the post request with the input credentials
   */
  postChangePasswordInfo(requestData: ChangePasswordData): Observable<ChangePasswordResponse> {
    const apiUrl = ApiUrls.AUTH_SERVICE.changePassword;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: ChangePasswordResponse) => {
          return response;
        }
      ),
    );
  }
}
