import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';


import { SettingsModule } from '@modules/settings/settings.module';
import { ScheduleWidgetModule } from '@modules/schedule-widget/schedule-widget.module';

import { SidebarModule } from 'primeng/sidebar';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    ComingSoonComponent,
    AuthLayoutComponent,
    UnauthorizedComponent,
    ContentLayoutComponent,
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    ScheduleWidgetModule,
    ReactiveFormsModule,
    FormsModule,
    SettingsModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ComingSoonComponent
  ]
})
export class LayoutModule { }
