import { BackNavigationConfig, DropdownOption } from '@models/generic-types';
import { CsaFormInfo } from '@models/csa-form-generics';
import { FamilyIllnessInfo } from '@models/medical-history-info';

export class CsaFormConfig {

    public static FORM_STATUS = {
        default: 'signed_up',
        inProgress: 'csa_in_progress',
        csaFormsubmitted: 'csa_submitted',
        evaluationInProgress: 'evaluation_in_progress',
        evaluationSubmitted: 'evaluation_submitted',
        diagnosisInProgress: 'diagnosis_in_progress',
        diagnosisSubmitted: 'diagnosis_submitted',

    };

    public static GO_TO_PROFILE_CONIFG: BackNavigationConfig = {
        enable: false,
        redirectUrl: 'client',
        backBtnLabel: 'Go To Profile'
    };

    public static FORM_CONFIG = {
        stepsCount: 10,
        formsCount: 8,
        formAllPages: 'ALL'
    };

    public static FORM_STEPS_DETAILS: CsaFormInfo[] = [
        {
            stepIndex: 0,
            isForm: false,
            showBtnBar: false,
            formTitle: 'Introduction',
            formIndex: -1,
            url: 'csa-form/intro'
        },
        {
            stepIndex: 1,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Personal Info',
            formIndex: 0,
            formPageNo: 1,
            url: 'csa-form/personal-info'
        },
        {
            stepIndex: 2,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Family Info',
            formIndex: 1,
            formPageNo: 2,
            url: 'csa-form/family-info'
        },
        {
            stepIndex: 3,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Medical Info',
            formIndex: 2,
            formPageNo: 3,
            url: 'csa-form/medical-info'
        },
        {
            stepIndex: 4,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Birth History Info',
            formIndex: 3,
            formPageNo: 4,
            url: 'csa-form/birth-history'
        },
        {
            stepIndex: 5,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Early History Info',
            formIndex: 4,
            formPageNo: 5,
            url: 'csa-form/early-history'
        },
        {
            stepIndex: 6,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Medical History Info',
            formIndex: 5,
            formPageNo: 6,
            url: 'csa-form/medical-history'
        },
        {
            stepIndex: 7,
            isForm: true,
            showBtnBar: true,
            formTitle: 'Behavioural History',
            formIndex: 6,
            formPageNo: 7,
            url: 'csa-form/behavioural-history'
        },
        {
            stepIndex: 8,
            isForm: false,
            showBtnBar: false,
            url: 'csa-form/submit-application',
            formTitle: 'Submit Application'
        },
    ];

    public static FORM_STEP_BTN = {
        next: 'next',
        prev: 'prev',
        submit: 'Submit'
    };

    public static FORM_STEPS_INFO = {

    };

    // -------- FAMILY INFO ----------

    public static LIVING_WITH: DropdownOption[] = [
        {
            value: '1',
            label: 'Natural Parents'
        },
        {
            value: '2',
            label: 'Single Parents'
        },
        {
            value: '3',
            label: 'Adoptive Parents'
        },
        {
            value: '4',
            label: 'Parent & Step Parent'
        },
        {
            value: '5',
            label: 'Others'
        },
    ];

    public static PARENTS_MARITAL_STATUS: DropdownOption[] = [
        {
            value: '1',
            label: 'Married'
        },
        {
            value: '2',
            label: 'Separated'
        },
        {
            value: '3',
            label: 'Divorced'
        },
        {
            value: '4',
            label: 'Widowed'
        },
        {
            value: '5',
            label: 'Single'
        },
    ];

    public static MONTHLY_FAMILY_INCOME: DropdownOption[] = [
        {
            value: '1',
            label: '$300'
        },
        {
            value: '2',
            label: '$300-$500'
        },
        {
            value: '3',
            label: '$500-$1000'
        },
        {
            value: '4',
            label: 'Over $1000'
        },
    ];

    public static SIBLING_RELATION: DropdownOption[] = [
        {
            value: '1',
            label: 'Natural Siblings'
        },
        {
            value: '2',
            label: 'Step'
        },
        {
            value: '3',
            label: 'Foster'
        },
    ];

    // -------- MEDICAL INFO -----------

    public static BODY_TYPE_LIST: DropdownOption[] = [
        {
            value: '1',
            label: 'Normal'
        },
        {
            value: '2',
            label: 'Heavy'
        },
        {
            value: '3',
            label: 'Thin'
        },
        {
            value: '4',
            label: 'Tall'
        },
        {
            value: '5',
            label: 'Short'
        },
    ];

    // -------- BIRTH HISTORY INFO --------

    public static MONTHS_LIST: DropdownOption[] = [
        {
            value: 'month_1',
            label: '1st Month'
        },
        {
            value: 'month_2',
            label: '2nd Month'
        },
        {
            value: 'month_3',
            label: '3rd Month'
        },
        {
            value: 'month_4',
            label: '4th Month'
        },
        {
            value: 'month_5',
            label: '5th Month'
        },
        {
            value: 'month_6',
            label: '6th Month'
        },
        {
            value: 'month_7',
            label: '7th Month'
        },
        {
            value: 'month_8',
            label: '8th Month'
        },
        {
            value: 'month_9',
            label: '9th Month'
        },
    ];

    public static ANASTHESIA_LIST: DropdownOption[] = [
        {
            label: 'Epidural',
            value: 'Epidural'
        },
        {
            label: 'Spinal',
            value: 'Spinal'
        },
        {
            label: 'Epidural/Spinal',
            value: 'Epidural/Spinal'
        },
        {
            label: 'General anaesthesia',
            value: 'General anaesthesia'
        },
        {
            label: 'None',
            value: 'nil'
        },
    ];

    public static BIRTH_HISTORY_PAIRS = {
        swelling_status: 'swelling_month',
        bp_status: 'bp_month',
        kidney_status: 'kidney_month',
        infection_status: 'infection_month',
        infectious_disease_status: 'infectious_disease_month',
        bleed_spot_status: 'bleed_spot_month',
        over_weight_status: 'over_weight_month',
        injury_status: 'injury_month'
    };

    // -------- EARLY HISTORY ---------

    public static GOOD_BAD_SCALE: DropdownOption[] = [
        {
            value: 'good',
            label: 'Good'
        },
        {
            value: 'Poor',
            label: 'Poor'
        },
        {
            value: 'average',
            label: 'Average'
        },
    ];


    // -------- LIABILITY RELEASE ---------
    public static FILLED_OUT_BY: DropdownOption[] = [
      {
          value: 'parent',
          label: 'Parent'
      },
      {
          value: 'client',
          label: 'Client'
      },
      {
          value: 'guardian',
          label: 'Guardian'
      },
    ];

    public static LIABILITY_STATEMENT: string = `I would like to participate in the tele-education program.
    I acknowledge the potential risks involved in the various activities suggested of the program
    (eg. Horseback riding, swimming, aerobics, gymnastics, normal play).
    I feel that the possible benefits to myself/son/daughter/ward are greater than the risk assumed.
    I hereby, intend to be legally bound, for myself, my heirs and assigns, executors or administrators,
    waive the release forever all claims for damages against the tele-education program, its Board of Directors,
    Instructors, Therapists, Aides, volunteers, and /or Employees for any and all injuries and/or losses I/my
    son/ my daughter/my ward may sustain while participating in the activities suggested.
    I hereby give permission to use photos and /or videos for advertising agents or news media, to use,
    reproduce or circulate and publicize these photos and/or videos by all means available to them
    (newspaper articles, television, brochures, social media and clinical or research documents). I understand that this information will be used for the primary purpose of promoting and aiding Home Integration work.
    `;

    // -------- MEDICAL HISTORY -------

    public static FAMILY_ILLNESS_LIST: FamilyIllnessInfo[] = [
        {
            id: '1',
            problem: 'Problem similar to child',
            status: false,
            kin_relation: ''
        },
        {
            id: '2',
            problem: 'Intellectual Disability',
            status: false,
            kin_relation: ''
        },
        {
            id: '3',
            problem: 'Mental Illness',
            status: false,
            kin_relation: ''
        },
        {
            id: '4',
            problem: 'Cerebral Palsy',
            status: false,
            kin_relation: ''
        },
        {
            id: '5',
            problem: 'Muscle problem such as dystrophy',
            status: false,
            kin_relation: ''
        },
        {
            id: '6',
            problem: 'Eye and or hearing problems',
            status: false,
            kin_relation: ''
        },
        {
            id: '7',
            problem: 'Birth defects',
            status: false,
            kin_relation: ''
        },
        {
            id: '8',
            problem: 'Epliepsy',
            status: false,
            kin_relation: ''
        },
    ];

    public static FUNCTIONAL_CAPACITY: DropdownOption[] = [
        {
            value: 'restrcited',
            label: 'Restrcited'
        },
        {
            value: 'unrestrcited',
            label: 'Unrestrcited'
        },
    ];

    public static PHYSICAL_ACTIVITY: DropdownOption[] = [
        {
            value: 'mild',
            label: 'Mild'
        },
        {
            value: 'moderate',
            label: 'moderate'
        },
        {
            value: 'limited',
            label: 'Limited'
        },
    ];

    // -------- BEHAVIOURAL HISTORY ------------

    public static SIBLING_RELATION_MODE: DropdownOption[] = [
        {
            value: 'good',
            label: 'Good'
        },
        {
            value: 'fair',
            label: 'Fair'
        },
        {
            value: 'poor',
            label: 'Poor'
        },
        {
            value: 'will_not_share',
            label: 'Will not share'
        },
        {
            value: 'loving',
            label: 'Loving and affectionate'
        },
        {
            value: 'hits_aggrevate',
            label: 'Hits or aggravates them'
        },
        {
            value: 'other',
            label: 'Other'
        },
    ];

    public static SPECIFIC_CONCERNS: DropdownOption[] = [
        {
            value: 'development',
            label: 'Development'
        },
        {
            value: 'emotional',
            label: 'Emotional'
        },
        {
            value: 'behavioural',
            label: 'Behavioural or activity level'
        },
        {
            value: 'get_along',
            label: 'Getting along with children'
        },
        {
            value: 'other',
            label: 'Other'
        },
    ];

    // ---------- FLIE UPLOAD -------------

    public static FILE_UPLOAD_VAL = {
        reference: "patient",
        referenceid: '',
        u_code: 1 //
    };

}
