import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ForgotPasswordData, ForgotPasswordResponse } from '@models/forgot-password';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) { }

    // ---------------- API METHODS ----------------

  /**
   * Author: Jeswin Chacko P A
   * @param { ForgotPasswordData } ForgotPasswordData
   * Description: Makes the post request with the input credentials
   */
  postForgotPasswordInfo(requestData: ForgotPasswordData): Observable<ForgotPasswordResponse> {
    const apiUrl = ApiUrls.AUTH_SERVICE.forgotPassword;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: ForgotPasswordResponse) => {
          return response;
        }
      ),
    );
  }
}
