<div class="container-wrap">

  <!-- TITLE -->
  <div class="page-info">
      <h3 class="title page-title">{{pageTitle}}</h3>
  </div>

  <!-- FORM -->
  <div class="container">
      <form class="login-form"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="onResetPassword()">
          <div class="row">
              <!-- Password -->
              <div class="col-12 mb-2">
                  <label class="label form-label">Password
                      <i class="fa colored fa-question-circle" aria-hidden="true" title="View Password Policy"
                      [popover]="passwordPolicyPopover" #resetPasswordPopover="bs-popover" placement="bottom" [outsideClick]="true"></i>
                  </label>
                  <div class="input-group">
                      <input class="form-control field-input"
                      formControlName="new_password"
                      placeholder="Enter password"
                      [type]="showPassword ? 'text': 'password'"
                      [maxLength]="fieldValidations.passwordMax">
                      <div class="input-group-append">
                      <span class="input-group-text trans-bgd">
                          <i
                          [title]="showPassword ? 'Hide Password': 'ShowPassword'"
                          class="icon eye-icon"
                          [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                          (click)="togglePasswordVisibility()">
                      </i>
                      </span>
                      </div>
                  </div>
                  <!-- Error Messages -->
                  <div class="form-error-con"
                  *ngIf=" isFormSubmitted && resetPasswordForm?.controls?.new_password?.errors?.required &&
                      (isFormSubmitted || resetPasswordForm.dirty || resetPasswordForm.touched )">
                      <span class="err-msg">
                      {{errMsgs.mandarotyFields}}
                      </span>
                  </div>
                  <div class="form-error-con"
                      *ngIf="
                      isFormSubmitted &&
                      !resetPasswordForm?.controls?.new_password?.errors?.required &&
                      resetPasswordForm?.controls?.new_password?.errors?.requiredPattern &&
                      (isFormSubmitted || resetPasswordForm?.dirty || resetPasswordForm?.touched )">
                      <span class="err-msg">
                      {{errMsgs.passwordPattern}}
                      </span>
                  </div>
              </div>
              <!-- Confirm Password -->
              <div class="col-12 mb-2">
                  <label class="label form-label">Confirm Password</label>
                  <div class="input-group">
                      <input
                          class="form-control field-input"
                          formControlName="confirm_password"
                          placeholder="Confirm password"
                          [type]="showConfirmPassword ? 'text': 'password'"
                          [maxLength]="fieldValidations.passwordMax">
                      <div class="input-group-append">
                      <span class="input-group-text trans-bgd">
                          <i
                          [title]="showConfirmPassword ? 'Hide Password': 'ShowPassword'"
                          class="icon eye-icon"
                          [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                          (click)="toggleConfirmPasswordVisibility()">
                      </i>
                      </span>
                      </div>
                  </div>
                  <!-- Error Messages -->
                  <div class="form-error-con"
                  *ngIf=" isFormSubmitted && resetPasswordForm?.controls?.confirm_password?.errors?.required &&
                      (isFormSubmitted || resetPasswordForm.dirty || resetPasswordForm.touched )">
                      <span class="err-msg">
                      {{errMsgs.mandarotyFields}}
                      </span>
                  </div>
                  <div class="form-error-con"
                      *ngIf=" isFormSubmitted &&
                      !resetPasswordForm?.controls?.confirm_password?.errors?.required &&
                      resetPasswordForm?.controls?.confirm_password?.errors?.requiredPattern &&
                      (isFormSubmitted || resetPasswordForm?.dirty || resetPasswordForm?.touched )">
                  <span class="err-msg">
                  {{errMsgs.passwordPattern}}
                  </span>
              </div>
              </div>
              <!-- Buttons -->
              <div class="col-12 mb-2">
                  <div class="field-con btn-con">
                      <button
                          class="btn cancel-btn"
                          type="submit"
                          (click)="onCancelClick()"
                          title="Cancel">Cancel
                      </button>
                      <button
                      class="btn submit-btn login-btn"
                      type="submit"
                      title="Reset Password">Reset Password
                      </button>
                  </div>
              </div>
          </div>
      </form>
  </div>

</div>

<!-- PASSWORD POLICY POPOVER -->
<ng-template #passwordPolicyPopover>
  <div class="popover-title  popover-header">
    <div class="header-password">
      <div class="password-title">
        <span>Password Policy</span>
      </div>
      <div class="close-button">
        <button type="button" class="close " aria-label="Close" title="Close">
        <span class="close-span igm_handcursor" aria-hidden="true"  (click)="resetPasswordPopover.hide()">&times;</span>
      </button>
      </div>
    </div>
  </div>
  <div class="password-help">
    <p> Please enter password with the following criteria </p>
    <ul>
      <li>Password must contain atleast 8 characters.</li>
      <li>Maximum password length is 30 characters.</li>
      <li>Password must contain one capital letter(A-Z).</li>
      <li>Password must contain one small letter(a-z).</li>
      <li>Password must contain one number(0-9).</li>
      <li
      [innerHtml]="'Password must contain alteast one special character from the following !#$%&()*+,-./:;=?@[\]^_`{|}~.'"
      ></li>
    </ul>
  </div>
</ng-template>
