<!-- APP LOADER -->
<ngx-loading-bar
    [color]="loadingBarConfig?.color">
</ngx-loading-bar>
<ngx-spinner
    [bdColor] = "spinnerConfig?.bdColor" 
    [size] = "spinnerConfig?.size" 
    [color] = "spinnerConfig?.color"  
    [fullScreen] = "spinnerConfig?.fullScreen">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<router-outlet></router-outlet>