import { Injectable } from '@angular/core';
import { LayoutManager } from '@models/layout-manager';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutManagementService {

  layoutManagerSubject = new BehaviorSubject<LayoutManager>({} as LayoutManager);
  layoutManager = this.layoutManagerSubject.asObservable();

  constructor() {
   }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { LayoutManager } layoutInfo
   * Description: Sets the user unique token in local storage
   */
  triggerLayoutSubjects(layoutInfo: LayoutManager) {
    this.layoutManagerSubject.next(layoutInfo);
  }

}
