import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SignInInfo, SignInResponseData } from '@models/sign-in';

import { SignInService } from './sign-in.service';
import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { LayoutManagementService } from '@services/layout-manager/layout-management.service';

import { AppConstants } from '@utilities/app-constants';
import { AuthConstants } from '@utilities/auth-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { InputFieldLimits } from '@utilities/input-field-limts';

import { LayoutManager } from '@models/layout-manager';
import { LoggedUserInfo } from '@models/logged-user';
import { CsaFormConfig } from '@modules/csa-form/csa-form-config';
import { CsaFormService } from '@modules/csa-form/csa-form.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  // Constants
  errMsgs: any;
  redirectUrls: any;
  pageTitle: string;
  fieldValidations: any;

  // Form related variables
  loginForm: FormGroup;

  // Flags
  showPassword: boolean;
  isFormSubmitted: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private csaFormService: CsaFormService,
    private signInService: SignInService,
    private utilityService: UtilityService,
    private alertMessageService: AlertMsgService,
    private layoutManagementService: LayoutManagementService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.showPassword = false;
    this.isFormSubmitted = false;
    this.redirectUrls = AppConstants.redirectUrls;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;
    this.pageTitle = AppConstants.pageTitles.signIn;
    this.fieldValidations = InputFieldLimits.loginForm;
   }

  ngOnInit(): void {
    this.initialiseLoginForm();
  }

  // Layout management
  manageHeaderLayout(showheader: boolean = false) {
    const layoutInfo: LayoutManager = {
      showHeaderNav: showheader
    };
    this.layoutManagementService.triggerLayoutSubjects(layoutInfo);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Initialises login form with necessary form fields
   */
  initialiseLoginForm() {
    this.authService.purgeAuth();
    localStorage.clear();
    this.sessionStorageService.clearLocalStorageValues();
    this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        role: [AuthConstants.mainRoleKeywords.client, [Validators.required]]
      },
    );
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Validates the form and calls the post API method based on the result
   */
  onLoginSubmit() {
  this.isFormSubmitted = true;
  if (this.loginForm.valid) {
    const loginData: SignInInfo = this.loginForm.value;
    this.postLoginInfo(loginData);
  }
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { LoginInfo } LoginData
   * Description: Send the login info to the  API
   */
  postLoginInfo(loginData: SignInInfo) {
    let requestData: SignInInfo = this.constructRequestData(loginData);
    this.signInService.postLoginInfo(requestData).subscribe(
      (response: SignInResponseData) => {
        if (response.status) {
            this.csaFormService.isCSAViewMode = false;
            this.utilityService.showResponseSuccess(ToastrMessages.LOGIN_FORM_MSGS.loginSuccess, response.message);
            const showheader = this.checkFormStatus(response.data.applnStatus);
            this.manageHeaderLayout(showheader);
            this.navigateToLandingPage(response.data);
        } else {
          this.utilityService.showResponseError(ToastrMessages.LOGIN_FORM_MSGS.loginFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.LOGIN_FORM_MSGS.loginFail, error.error.message );
        }
    }
    );
  }

  navigateToLandingPage(userInfo: LoggedUserInfo) {
    let redirectUrl = AppConstants.redirectUrls.csaForm;
    // Check application status and navigate user accordingly
    const intialState = this.checkFormStatus(userInfo.applnStatus);
    if (intialState) {
      redirectUrl = AppConstants.redirectUrls.client;
    }
    this.router.navigateByUrl(redirectUrl);
  }

  checkFormStatus(applnStatus): boolean {
    let retVal = true;
    if (
      (applnStatus == CsaFormConfig.FORM_STATUS.default)
      || (applnStatus == CsaFormConfig.FORM_STATUS.inProgress)) {
      retVal = false;
    }
    return retVal;
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: COnstructs requestdata for login API request
    */
  constructRequestData(loginData: SignInInfo): SignInInfo {
    loginData.password = this.utilityService.hashPassword(loginData.password);
    const requestData: SignInInfo = loginData;
    return requestData;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Toggles the flag which controls password visibility
   */
  togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Resets the login form
   */
  resetLoginForm() {
    this.loginForm.reset();
  }

}
