<div class="container pt-0">
  <div class="logo-con">
      <div class="logo-banner">
          <img class="logo logo-large" src="assets/images/main-logo.png">
          <span class="banner-title company-name">Welcome to “Our Child’s Life”</span>
      </div>
  </div>
  <div class="message-con">
      <span class="welcome-msg">
        We are here to help you navigate through the development stages of your child’s life
      </span>
      <span class="normal-text">
        “Our Child’s Life” online platform was designed to assist families
        in helping their child meet their developmental milestones.
        This platform was implemented by Home Integration, Inc. to
        reach more families with our one-of-a-kind program.
      </span><br><br>
      <span class="normal-text">
        Home Integration Child Development Center was established in
        1976 by Father Paul Zahler, O.S.B.,Ph.D. on the campus of
        Saint Gregory’s University in Shawnee, Oklahoma.
        This program has currently served over 8,000 children and
        More than 40,000 adults with delays in development.
      </span><br><br>
      <span class="normal-text">
        This program is recognized internationally for developing innovative
        curriculum to meet the individual needs of the child or adult.
        This curriculum, known as “Experience Quotient” is considered
        to be one of the most effective developmental strategies
        of our time and enables children to grow and develop to their greatest potential.
      </span><br><br>
      <span class="normal-text">
        “Our Child’s Life” will have “Experience Quotient” experts with
        over 40 years experience in working with children with
        delays in development.  These experts will create an individualized
        plan to guide you through your child’s development.
      </span><br><br>
      <span class="normal-text">
        “Our Child’s Life” is pleased to welcome you to this valuable program.
        We are looking forward to working together with you to meetyour child’s needs.
      </span><br><br>
      <span class="welcome-msg">
        Please visit our website:  <a href="https://nidd.us/home">nidd.us</a>
      </span>
      <span class="normal-text">
          please click on the button below to goto Login Page.
      </span>
  </div>
  <div class="btn-con">
      <button
          class="btn submit-btn login-btn"
          type="submit"
          title="Child Service Application Form"
          (click)="goToLoginPage()">Login
      </button>
  </div>
</div>
