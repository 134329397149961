import { FormControl } from '@angular/forms';

export function requiredFileType( types: string[] ) {
    return function (control: FormControl) {
      let file;
      if (control.value) {
        file = control.value;
      }
      if (file) {
        const extension = file.type.toLowerCase();
        const matchResult = types.findIndex(
          (fileType: string) => fileType.toLowerCase() === extension.toLowerCase()
        );
        if (matchResult < 0) {
          return {
            requiredFileType: true
          };
        }  
        return null;
      }
      return null;
    };
}

export function requiredFileSize( maxLimit: number ) {
  return function (control: FormControl) {
    let file;
    if (control.value) {
      file = control.value;
    }
    if (file) {
      const size = file.size;
      if ( size > maxLimit ) {
        return {
          requiredFileSize: true
        };
      }
      return null;
    }
    return null;
  };
}

export function requiredPattern( pattern: RegExp ) {
  return function (control: FormControl) {
    let inputVal;
    let isValid = false;
    let retValue = null;
    if (control.value) {
      inputVal = control.value;
      isValid = pattern.test(inputVal);
    }
    if (isValid) {
      retValue = null; 
    } else {
      retValue = {requiredPattern: true}; 
    }
    return retValue;
    }

}