<div class="container-wrap">

    <!-- TITLE -->
    <div class="page-info">
        <h3 class="title page-title">{{pageTitle}}</h3>
    </div>

    <!-- FORM -->
    <div class="container">
        <form class="login-form"
            [formGroup]="signUpForm" 
            (ngSubmit)="onCreateAccount()">
            <div class="row">
                <!-- First Name -->
                <div class="col-12 mb-2">
                    <label class="label form-label">First Name</label>
                    <input 
                        type="text" 
                        class="form-control field-input" 
                        placeholder="Enter first name"
                        formControlName="fname">
                <!-- Error Messages -->
                <div 
                    class="form-error-con" 
                    *ngIf=" isFormSubmitted && signUpForm?.controls?.fname?.errors?.required &&  
                    (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                    <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                </div>                        
                </div>
                <!-- Middle Name -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Middle Name</label>
                    <input 
                        type="text" 
                        class="form-control field-input" 
                        placeholder="Enter middle name"
                        formControlName="mname">
                    <!-- Error Messages -->
                    <div 
                    class="form-error-con" 
                    *ngIf=" isFormSubmitted && signUpForm?.controls?.mname?.errors?.required &&  
                    (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                        <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                    </div>                        
                </div>
                <!-- Last Name -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Last Name</label>
                    <input 
                        type="text" 
                        class="form-control field-input" 
                        placeholder="Enter last name"
                        formControlName="lname">
                <!-- Error Messages -->
                <div 
                    class="form-error-con" 
                    *ngIf=" isFormSubmitted && signUpForm?.controls?.lname?.errors?.required &&  
                    (isFormSubmitted || signUpForm?.dirty || signUpForm?.touched )">
                    <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                </div>                        
                </div>
                <!-- Email ID -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Email ID</label>
                    <input 
                        type="text" 
                        class="form-control field-input" 
                        placeholder="Enter email"
                    formControlName="emailID">
                    <!-- Error Messages - Mandatory field -->
                    <div 
                        class="form-error-con" 
                        *ngIf=" isFormSubmitted && signUpForm?.controls?.emailID?.errors?.required &&  
                        (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                    <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                    </div>
                    <!-- Error Messages - Email format -->
                    <div 
                        class="form-error-con" 
                        *ngIf="isFormSubmitted && signUpForm?.controls?.email?.errors?.requiredPattern &&  
                            signUpForm?.value.email && (signUpForm?.dirty || signUpForm?.touched )">
                        <span class="err-msg">{{errMsgs?.invalidEmail}}</span>
                    </div>
                </div>
                <!-- Contact Number -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Contact Number</label>
                    <input 
                        type="text"
                        required  
                        [mask]='generalValidations?.contactNumberMask'
                        class="form-control field-input" 
                        placeholder="Enter contact number"
                        [maxlength]="generalValidations?.contactNumberMax" 
                        formControlName="phone">
                    <!-- Error Messages - Mandatory field -->
                    <div 
                        class="form-error-con" 
                        *ngIf=" isFormSubmitted && signUpForm?.controls?.phone?.errors?.required &&  
                        (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                    <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                    </div>
                </div>
                <!-- Password -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Password 
                        <i class="fa colored fa-question-circle" aria-hidden="true" title="View Password Policy"
                        [popover]="passwordPolicyPopover" #resetPasswordPopover="bs-popover" placement="bottom" [outsideClick]="true"></i>
                    </label>
                    <div class="input-group">
                        <input class="form-control field-input" 
                        formControlName="password1"
                        placeholder="Enter password"
                        [type]="showPassword ? 'text': 'password'" 
                        [maxLength]="fieldValidations.passwordMax">
                        <div class="input-group-append">
                        <span class="input-group-text trans-bgd">
                            <i
                            [title]="showPassword ? 'Hide Password': 'ShowPassword'"
                            class="icon eye-icon"
                            [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                            (click)="togglePasswordVisibility()">
                        </i>
                        </span>
                        </div>
                    </div>
                    <!-- Error Messages -->
                    <div class="form-error-con"
                    *ngIf=" isFormSubmitted && signUpForm?.controls?.password1?.errors?.required &&  
                        (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                        <span class="err-msg">
                        {{errMsgs.mandarotyFields}}
                        </span>
                    </div>
                    <div class="form-error-con"
                        *ngIf=" 
                        isFormSubmitted && 
                        !signUpForm?.controls?.password1?.errors?.required &&
                        signUpForm?.controls?.password1?.errors?.requiredPattern &&  
                        (isFormSubmitted || signUpForm?.dirty || signUpForm?.touched )">
                        <span class="err-msg">
                        {{errMsgs.passwordPattern}}
                        </span>
                    </div>
                </div>
                <!-- Confirm Password -->
                <div class="col-12 mb-2">
                    <label class="label form-label">Confirm Password</label>
                    <div class="input-group">
                        <input 
                            class="form-control field-input" 
                            formControlName="password2"
                            placeholder="Confirm password"
                            [type]="showConfirmPassword ? 'text': 'password'" 
                            [maxLength]="fieldValidations.passwordMax">
                        <div class="input-group-append">
                        <span class="input-group-text trans-bgd">
                            <i
                            [title]="showConfirmPassword ? 'Hide Password': 'ShowPassword'"
                            class="icon eye-icon"
                            [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                            (click)="toggleConfirmPasswordVisibility()">
                        </i>
                        </span>
                        </div>
                    </div>
                    <!-- Error Messages -->
                    <div class="form-error-con"
                    *ngIf=" isFormSubmitted && signUpForm?.controls?.password2?.errors?.required &&  
                        (isFormSubmitted || signUpForm.dirty || signUpForm.touched )">
                        <span class="err-msg">
                        {{errMsgs.mandarotyFields}}
                        </span>
                    </div>
                    <div class="form-error-con"
                        *ngIf=" isFormSubmitted &&  
                        !signUpForm?.controls?.password2?.errors?.required &&
                        signUpForm?.controls?.password2?.errors?.requiredPattern &&  
                        (isFormSubmitted || signUpForm?.dirty || signUpForm?.touched )">
                    <span class="err-msg">
                    {{errMsgs.passwordPattern}}
                    </span>
                </div>
                </div>
                <!-- Buttons -->
                <div class="col-12 mb-2">
                    <div class="field-con btn-con">
                        <button 
                            class="btn cancel-btn" 
                            type="submit" 
                            (click)="onCancelClick()"
                            title="Cancel">Cancel
                        </button>
                        <button 
                        class="btn submit-btn login-btn" 
                        type="submit" 
                        title="Create Account">Create Account
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>

<!-- PASSWORD POLICY POPOVER -->
<ng-template #passwordPolicyPopover>
    <div class="popover-title  popover-header">
      <div class="header-password">
        <div class="password-title">
          <span>Password Policy</span>
        </div>
        <div class="close-button"> 
          <button type="button" class="close " aria-label="Close" title="Close">
          <span class="close-span igm_handcursor" aria-hidden="true"  (click)="resetPasswordPopover.hide()">&times;</span>
        </button>
        </div>
      </div>
    </div>
    <div class="password-help">
      <p> Please enter password with the following criteria </p>
      <ul>
        <li>Password must contain atleast 8 characters.</li>
        <li>Maximum password length is 30 characters.</li>
        <li>Password must contain one capital letter(A-Z).</li>
        <li>Password must contain one small letter(a-z).</li>
        <li>Password must contain one number(0-9).</li>
        <li 
        [innerHtml]="'Password must contain alteast one special character from the following !#$%&()*+,-./:;=?@[\]^_`{|}~.'"
        ></li>
      </ul>
    </div>
</ng-template>