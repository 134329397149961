import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UtilityService } from '@utilities/utility.service';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { LoggedUserInfo } from '@models/logged-user';
import { CsaFormConfig } from '@modules/csa-form/csa-form-config';
import { AppConstants } from '@utilities/app-constants';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  tokenValue;
  proceedStatus: boolean;
  userInfoSubscription$: Subscription;
  userInfo: LoggedUserInfo;

  constructor(
    private router: Router,
    private utilityService: UtilityService,
    private authService: AuthService,
  ) {
    this.proceedStatus = true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot,):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.proceedStatus = true;
    const isTokenPresent = this.utilityService.checkTokenInStorage();
    if (isTokenPresent) {
      this.proceedStatus = false;
      //-------------------------------------------------------------------
      this.userInfoSubscription$ = this.authService.currentUser.subscribe(
        (user: LoggedUserInfo) => {
          this.userInfo = user;
          if ((this.userInfo.applnStatus == CsaFormConfig.FORM_STATUS.default) || (this.userInfo.applnStatus == CsaFormConfig.FORM_STATUS.inProgress)) {
            let redirectUrl = AppConstants.redirectUrls.csaForm;
            this.router.navigateByUrl(redirectUrl);

          } else
          //----------------------------------------------------------------------
          {
            //   this.utilityService.redirectToLandingPage();

            // at times,log out takes to prifile page...to prevent that strict check is applied.
            if (!state.url.includes('login')) {
              this.utilityService.redirectToLandingPage();
            } else {
              const isnotLoggedOut = this.utilityService.checkTokenInStorage();
              if (isnotLoggedOut) { this.utilityService.redirectToLandingPage(); }
            }

          }
        });
    }
    else { 
      return this.proceedStatus;
    }
  }

}

