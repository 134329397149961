import { Injectable } from '@angular/core';
import { LoggedUserInfo } from '@models/logged-user';
import { AppConstants } from '@utilities/app-constants';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  userInfoSubscription$ = new Subscription();
  userData: LoggedUserInfo;

  constructor(
    private authService: AuthService
  ) {
    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (userData: LoggedUserInfo) => {
        this.userData = userData;
      }
    );
  }


  getUserTimeZone() {
    const timeZone = moment.tz.guess();
    if (this.userData && this.userData.timezone) {
      if (this.userData.timezone.toUpperCase() === AppConstants.timeZoneList.deviceTimeZone) {
        return timeZone;
      } else if (this.userData.timezone.toUpperCase() === AppConstants.timeZoneList.orgTimeZone) {
        // to be replaced
        // return timeZone;
        return this.userData.organisation.timezone;
      } else {
        return timeZone;
      }
    } else {
      return timeZone;
    }
  }

  getToday() {
    return moment().utc().tz(this.getUserTimeZone()).format('hh:mm A MMM DD, YYYY');
  }

  getOrganizationUtc(date: any) {
    /* const d = moment(date).format('DD-MM-YYYY hh:mm A'); // date to a string without timezone info
    return moment.tz(d, 'DD-MM-YYYY hh:mm A', this.getUserTimeZone()).utc().valueOf(); */
    const d = moment(date).format('DD-MM-YYYY hh:mm A'); // date to a string without timezone info
    return moment.tz(d, 'DD-MM-YYYY hh:mm A', this.getUserTimeZone()).utc().valueOf();
  }

  /**
 * @returns year
 */
  getYear(date) {
    return moment(date).tz(this.getUserTimeZone()).format('YYYY');
  }

  /**
   * @returns Month
   */
  getMonth(date) {
    return moment(date).tz(this.getUserTimeZone()).format('MM');
  }

  /**
   * @returns Day
   */
  getDay(date) {
    return moment(date).tz(this.getUserTimeZone()).format('DD');
  }

  /**
 * @returns utc of any date
 */
  getStartOfDate(date, format) {
    return moment.tz(date, format, this.getUserTimeZone()).startOf('day').valueOf();
  }

  getDeviceTimeZone(): string {
    return  moment.tz.guess();
  }

  getOrganisationTimeZone() {
    return this.userData && this.userData.organisation.timezone ? this.userData.organisation.timezone : '';
  }
}
