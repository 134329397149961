import { Component, OnInit } from '@angular/core';
import { SessionData } from '@models/appointments';
import { AppointmentsService } from '@modules/appointments/appointments-service.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit {

  showVideoScreen: boolean;
  sessionData: SessionData;

  constructor(private appointmentsService: AppointmentsService) {
    this.showVideoScreen = false;
  }

  ngOnInit(): void {
    this.appointmentsService.sessionData.subscribe(sessionData => {
      this.triggerVideoScreen(sessionData);
    });
  }

  triggerVideoScreen(sessionData) {
    this.showVideoScreen = true;
    this.sessionData = sessionData;
  }

  closeVideoScreen() {
    this.showVideoScreen = false;
  }
}
