import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthGuard } from '@guards/no-auth/no-auth.guard';
import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { TermsConditionsComponent } from '@modules/generic/terms-conditions/terms-conditions.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        canActivate: [NoAuthGuard],
        component: SignInComponent
      },
      {
        path: 'sign-up',
        canActivate: [NoAuthGuard],
        component: SignUpComponent
      },
      {
        path: 'confirm',
        component: ActivateAccountComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent
      },
    ]
  },
  {
    path: 'welcome',
    component: WelcomeComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule { }
