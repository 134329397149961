import { TableFilter } from '@models/table-filter';
import { ConfirmDialog } from '@models/confirm-dialog';
import { DropdownOption } from '@models/generic-types';
import { TableConfigConstants } from './table-config-constants';
import { PrimeNgTableActions, PrimeNgTableColumns, PrimeNgTableSettings } from '@models/prime-ng-table';
import { environment } from '@env/environment';

export class AppConstants {

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores redirect urls
     */
    public static redirectUrls = {
        emptyRoute: '/',
        welcome: '/welcome',
        login: '/auth/login',
        signUp: '/auth/sign-up',
        forgotPassword: '/auth/forgot-password',
        termsConditions: '/auth/terms-conditions',
        activityCalenderForm: '/profile/activitycalender',
        client: '/profile',
        csaFormCiewMode: '/csa-form',
        csaForm: '/csa-form/intro',
        csaLandingForm: '/csa-form/personal-info',
        payment: '/payment',
        invoices: '/invoices',
        invoiceView: '/invoices/view',
        paymentANetUrl: environment.paymentGatewayUrl,
        paymentStatus: '/payment/status',
        careprovider: 'http://18.219.52.202:8084/auth/login', //care_provider redirect upon activation
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Page titles
     */
    public static pageTitles = {
        signIn: 'Sign In',
        signUp: 'Sign Up',
        forgotPassword: 'Forgot Password',
        resetPassword: 'Reset Password',
        changePassword: 'Change Password',
        personalInfo: 'Personal Info',
        familyInfo: 'Family Info',
        medicalInfo: 'Medical Info',
        EarlyHistory: 'Early History',
        birthHistoryInfo: 'Birth History Info',
        medicalHistoryInfo: 'Medical History Info',
        behaviouralInfo: 'Behavioral History',
        activities: 'Development Treatment Plan',
        feedback: 'Feedback Form',
        invoices: 'Invoices',
        payment: 'Payments',
        createTreatmentPlan: 'Developmental Activity Plan Detail',
        settings: 'Settings',
        invoiceHeading: 'INVOICE',
    }

    public static generalFormats: {
        date: 'M dd, yy'
    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Button text
     */
    public static buttonTexts = {
        confirm: 'Confirm',
        create: 'Create',
        submit: 'Submit',
        cancel: 'Cancel',
        close: 'Close',
        update: 'Update',
    };

    public static genericMessages = {
        noEvaluation: 'No initial assessment has been added',
        noTeatmentPlan: 'No developmental activity plan has been added',
        noActivities: 'No developmental activities has been added',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Default option values
     */
    public static defaultValues = {
        dropdownDefault: { value: '', label: 'Select'},
        noDataFound: 'No Data Found'
    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Pipe append values
     */
    public static valuePipes = {
        agePipe: ' Years',
        dateFormat: 'M dd, yy'
    };

    // --------- GENRIC CONSTANTS --------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Gender options
     */
    public static genderList: DropdownOption[] = [
        {
            value: 1,
            label: 'Male'
        },
        {
            value: 2,
            label: 'Female'
        },
        {
            value: 3,
            label: 'Prefer not to say'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: State options
     */
    public static stateList: DropdownOption[] = [
        {
            value: 1,
            label: 'Okalohoma'
        },
        {
            value: 2,
            label: 'Texas'
        },
        {
            value: 3,
            label: 'California'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Country options
     */
    public static countryList: DropdownOption[] = [
        {
            value: 1,
            label: 'USA'
        },
        {
            value: 2,
            label: 'UK'
        },
        {
            value: 3,
            label: 'Canada'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Yes No options
     */
    public static yesNoList: DropdownOption[] = [
        {
            value: 1,
            label: 'Yes'
        },
        {
            value: 2,
            label: 'No'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Parent relationship options
     */
    public static parentRelationshipList: DropdownOption[] = [
        {
            value: 1,
            label: 'Step Father'
        },
        {
            value: 2,
            label: 'Step Mother'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Appln Status based styles
     */
    public static applnStatusDisplaytexts: DropdownOption[] = [
        {
            value: 'signed_up',
            label: 'Signed Up'
        },
        {
            value: 'pending',
            label: 'Pending'
        },
        {
            value: 'csa_in_progress',
            label: 'CSA in progress'
        },
        {
            value: 'csa_submitted',
            label: 'Form submitted'
        },
        {
            value: 'diagnosis_submitted',
            label: 'Diagnosis submitted'
        },
        {
            value: 'evaluation_in_progress',
            label: 'Evaluation in progress'
        },
        {
            value: 'evaluation_submitted',
            label: 'Evaluation Submitted'
        },
        {
            value: 'diagnosis_in_progress',
            label: 'Diagnosis in progress'
        },
        {
            value: 'diagnosis_submitted',
            label: 'Diagnosis submitted'
        },
        {
            value: 'tp_in_progress',
            label: 'Developmental Activity Plan in progress'
        },
        {
            value: 'tp_submitted',
            label: 'Developmental Activity plan submitted'
        },
    ];

    public static currentStateOptions: DropdownOption[] = [
      {
          value: 3,
          label: 'Improved'
      },
      {
          value: 2,
          label: 'Stay the Same'
      },
      {
          value: 1,
          label: 'Regressed'
      },
    ];

    // ---------- LOGOUT ------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Confirm dialog of logout
     */
    public static logoutConfirmDialog: ConfirmDialog = {
        actionMode: '',
        title: 'Logout',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
        message: 'Unsaved changes(if any) will be lost! Are you sure, you want to logout?',
    };

    public static paymentWarningConfirmDialog: ConfirmDialog = {
        actionMode: '',
        title: 'Warning',
        cancelBtnText: 'Close',
        isShowConfirm: false,
        confirmBtnText: '',
        message: 'CSA Form Submitted. Please Complete the Appointment Fee Payment in Invoices section for further services',
    };

    // ---------- FOOTER ------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for registered users list
     */
    public static footerContent = {
        textContent: 'Home Integration Client Portal',
    };

    // ----------- PROFILE -----------

    public static patientProfileQueryParams: any = {
        mode: 'patients',
        sort_order: 'asc',
        sort_key: 'name',
        page: 1,
        keyword: '',
        applnStatus: 'csa_submitted',
        patientId: '',
    };

    public static activityStatus = {
    completed: 'completed',
    pending: 'pending',
    };

    public static APPLN_STATUS = {
    default: 'signed_up',
    pending: 'pending',
    inProgress: 'csa_in_progress',
    csaFormsubmitted: 'csa_submitted',
    treatmentplanInProgress: 'treatmentplan_in_progress',
    treatmentplanSubmitted: 'treatmentplan_submitted',
    };


    // ---------- TABLE CONSTANTS ------------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Filter configuration for startDate
     */
    public static startDateFilter: TableFilter = {
    type: 'dateOnly',
    filterId: 'start_date',
    optionLabel: 'label',
    optionValue: 'key',
    emptyFilterMessage: 'No data found',
    showAllOption: true,
    selectedOption: '',
    defaultValue: '',
    filterTitle: 'Added Start Date',
    options: []
    };

    /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Filter configuration for endDate
    */
    public static endDateFilter: TableFilter = {
    type: 'dateOnly',
    filterId: 'end_date',
    optionLabel: 'label',
    optionValue: 'key',
    emptyFilterMessage: 'No data found',
    showAllOption: true,
    selectedOption: '',
    defaultValue: '',
    filterTitle: 'Added End Date',
    options: []
    };

    /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Filter configuration for CSA Status
    */
    public static applnStatusFilter: TableFilter = {
    type: 'dropdown',
    filterId: 'applnStatus',
    showAllOption: true,
    optionLabel: 'label',
    optionValue: 'value',
    selectedOption: '',
    defaultValue: '',
    filterTitle: 'Application Status',
    options: AppConstants.applnStatusDisplaytexts,
    };

    // ----------- ACTIVITY DETAILS  ---------------

    public static recurrenceTypes: DropdownOption[] = [
        {
            value: 'daily',
            label: 'Daily'
        },
        {
            value: 'weekly',
            label: 'Weekly'
        },
    ];

    public static weekDaysList: DropdownOption[] = [
        {
            label: 'MON',
            value: 0
        },
        {
            label: 'TUES',
            value: 1
        },
        {
            label: 'WED',
            value: 2
        },
        {
            label: 'THU',
            value: 3
        },
        {
            label: 'FRI',
            value: 4
        },
        {
            label: 'SAT',
            value: 5
        },
        {
            label: 'SUN',
            value: 6
        },
    ];

    // ----------------- TREATMENT PLAN -----------------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for admin users list
     */
    public static treatmentActivityTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'activity_name',
            header: 'Activity Name'
        },
        {
            type: 'data',
            field: 'domainName',
            header: 'Domain'
        },
        {
            type: 'data',
            field: 'sensorName',
            header: 'Sensor'
        },
        {
            type: 'data',
            field: 'recurrence',
            header: 'Recurrence'
        },
        {
            type: 'date',
            field: 'start_date',
            header: 'Start Date'
        },
        {
            type: 'date',
            field: 'end_date',
            header: 'End Date'
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table settings of activity lists
     */
    public static activityTableSettings: PrimeNgTableSettings = {
        tableId: 'activity',
        showServerSearch: false,
        rowIdentifierKey: 'id',
        expandableRows: false,
        noDataText: 'No Activities found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search activities',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 20,
            totalRecords: 0,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [15, 20, 30]
        }
    };

        /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Activity table options
     */
    public static treatmentActivityTableActions: PrimeNgTableActions[] = [
        {
            key: TableConfigConstants.TABLE_ACTION_KEYS.viewItem,
            action: 'view',
            label: 'View',
            icon: 'fa-eye',
            enable: true,
        },
    ];

    // -------------- SETTINGS ------------------

    public static timeZoneList = {
        orgTimeZone: 'ORG_TIMEZONE',
        deviceTimeZone: 'DEVICE_TIMEZONE'
    };

    public static Localisation = {
      currency: '$',
    };
}
