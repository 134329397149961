import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertMsg } from '@models/alert-msgs';

import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { AppConstants } from '@utilities/app-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { ActivateAccountRequest, ActivateAccountResponse } from '@models/activate-account';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  activationCode: any;

  // Subscriptions
  routeSubscription$ = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private alertMessageService: AlertMsgService,
  ) {
    this.activationCode = '';
    this.authService.purgeAuth();
    this.subscribeToRouterParams();
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.routeSubscription$.unsubscribe();
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Extracts the user key from the url and makes the API request for activating
   * the user account
   */
  subscribeToRouterParams() {
    this.routeSubscription$ = this.activatedRoute.queryParams
      .subscribe(params => {
        if (params && params.activation_code) {
          this.activationCode = params.activation_code;
          this.activateUserAccount();
        } else {
          this.showErrorToastr();
        }
      });
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: API call for activateting the account
   */
  activateUserAccount() {
    const requestData: ActivateAccountRequest = this.constructRequestData();
    this.authService.activateUserAccount(requestData).subscribe(
      (response: ActivateAccountResponse) => {

        if (response.data.role === 'patient') {
          const alert: AlertMsg = {
            msg: ToastrMessages.ACCOUNT_ACTIVATION.activateAccountSuccess,
            title: ToastrMessages.TOASTR_TITLES.success
          };
          this.alertMessageService.showSuccessToster(alert);
          this.router.navigateByUrl(AppConstants.redirectUrls.welcome);
        } else {

            document.location.href = AppConstants.redirectUrls.careprovider;
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.showErrorToastr();
          this.router.navigateByUrl(AppConstants.redirectUrls.login);
        }
      }
    );
  }

  constructRequestData(): ActivateAccountRequest {
    const requestData: ActivateAccountRequest = {
      activation_code: this.activationCode
    };
    return requestData;
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Displays error alert for failed api response
    */
  showErrorToastr() {
    const alert: AlertMsg = {
      msg: ToastrMessages.ACCOUNT_ACTIVATION.activateAccountFail,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.alertMessageService.showErrorToster(alert);
  }

}
