import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@services/auth/auth.service';
import { UtilityService } from '@utilities/utility.service';
import { LoggedUserInfo } from '@models/logged-user';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AlertMsg } from '@models/alert-msgs';
import { ToastrMessages } from '@utilities/toastr-messages';
import { CsaFormConfig } from '@modules/csa-form/csa-form-config';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  // User Info
  userInfo: LoggedUserInfo;

  // Flags
  allowRouting: boolean;
  isTokenPresent: boolean;

  // Subscriptions
  userInfoSubscription$ = new Subscription();

  constructor (
    private authService: AuthService,
    private utilityService: UtilityService,
    private alertMsgService: AlertMsgService,
  ) {
    this.allowRouting = true;
    this.subscribeToUserInfo();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Checks if token is present
    this.allowRouting = this.authService.isAuthenticated();

    if (!this.allowRouting) {
      this.utilityService.redirectToLogin();
    }

    // Role compatibility check
    // if (this.allowRouting) {
    //   //changed for appointment
    //   // this.allowRouting = this.checkRoleCompatability(route);
    // }

    // Form status check
    // if (this.allowRouting) {
    //   this.allowRouting = this.checkCSAFormStatus(route);
    // }
    if (!this.allowRouting) {
      const alert: AlertMsg = { title: ToastrMessages.TOASTR_TITLES.error, msg: ToastrMessages.AUTH_MESSAGES.unauthorizedAccess};
      this.alertMsgService.showErrorToster(alert);
      this.utilityService.redirectToLogin();
    }

    return this.allowRouting;

  }

  checkRoleCompatability(route): boolean {
    let isRoleMatching = false;
    const expectedRoles: string[] = route.data.expectedRole;
    const identifiedRole = expectedRoles.find((roleInfo: string) => roleInfo == this.userInfo.role);
    if (identifiedRole != undefined) {
      isRoleMatching = true;
    }
    return isRoleMatching;
  }

  checkCSAFormStatus(route): boolean {
    let allowNavigation = true;
    const checkFormStatus = route.data.checkFormStatus;
    if (checkFormStatus) {
      let formStatus = this.userInfo.applnStatus;
      console.log(formStatus)
      if (formStatus == CsaFormConfig.FORM_STATUS.csaFormsubmitted) {
        allowNavigation = true;
      } else {
        allowNavigation = false;
      }
    }
    return allowNavigation;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Fetches user info for checking role match
   */
  subscribeToUserInfo() {
    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (user: LoggedUserInfo) => {
        this.userInfo = user;
      }
    );
  }

}
