import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleWidgetComponent } from './schedule-widget.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';



@NgModule({
  declarations: [ScheduleWidgetComponent],
  exports: [ScheduleWidgetComponent],
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule
  ]
})
export class ScheduleWidgetModule { }
