
export class ServiceList {

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Contains an array of auth related api urls
   */
  public static authServiceApiLists = [
    'login/',
    'logout/',
    'signup/',
    'token',
    'getLoggedinUser/',
    'v1/signup/confirm',
    'user/reset-user-password/',
    'country',
    'states',
    'upload',
    'forgot/password',
    'reset/password',
    'change/password',
    'update/settings',
    'download/invoice',
  ];

  /**
  * Author: Gokul Krishna
  * Description: Contains an array of care provider feature based urls
  */
  public static commonServiceApiLists = [
    'getcalenderdates',
    'getSchedules',
    'getappointment',
    'telemedicine',
    'archive',
  ];

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Contains an array of client feature based urls
   */
  public static clientServiceApiLists = [
      'savecsatmp',
      'getcsatmp',
      'getcsafinal',
      'savecsafinal',
      'getcsafinal',
      'patientprofile',
      'getfeedbackqns',
      'completeactivity',
      'gettpcalenderdates',
      'getinvoices',
      'getpaymentform', // found in both client and shared
      'updatepayment', // found in both client and shared
      'gettpactivities', // found in both client and cp
  ];

  public static cpServiceApiLists = [
    'listpatients',
    'gettpcalenderdates',
    'getactivitylist',
    'saveactivity',
    'getdomainssensors',
    'gettpdetails',
    'startsession/',
    'savetp',
    'gettpchangelog',
  ];
}

