<div class="container-wrap">
    <!-- TITLE -->
    <div class="page-info">
        <h3 class="title page-title">{{pageTitle}}</h3>
    </div>

    <!-- FORM -->
    <div class="container">
        <form class="login-form"
            [formGroup]="loginForm"
            (ngSubmit)="onLoginSubmit()">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="label form-label">Email ID</label>
                    <input
                        type="text"
                        class="form-control field-input"
                        placeholder="Enter email"
                        formControlName="email"
                        >
                 <!-- Error Messages -->
                 <div
                    class="form-error-con"
                    *ngIf=" isFormSubmitted && loginForm?.controls?.email?.errors?.required &&
                    (isFormSubmitted || loginForm.dirty || loginForm.touched )">
                     <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                 </div>
                 <div
                    class="form-error-con"
                    *ngIf=" isFormSubmitted && loginForm?.controls?.email?.errors?.email &&
                    (isFormSubmitted || loginForm.dirty || loginForm.touched )">
                    <span class="err-msg">{{errMsgs?.invalidEmail}}</span>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="label form-label">
                        Password
                    </label>
                    <div class="input-group">
                        <input class="form-control field-input"
                        formControlName="password"
                        placeholder="Enter password"
                        [type]="showPassword ? 'text': 'password'"
                        [maxLength]="fieldValidations.passwordMax">
                        <div class="input-group-append">
                          <span class="input-group-text trans-bgd">
                            <i
                            [title]="showPassword ? 'Hide Password': 'ShowPassword'"
                            class="icon eye-icon"
                            [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                            (click)="togglePasswordVisibility()">
                        </i>
                          </span>
                        </div>
                    </div>
                    <!-- Error Messages -->
                    <div class="form-error-con"
                    *ngIf=" isFormSubmitted && loginForm?.controls?.password?.errors?.required &&
                        (isFormSubmitted || loginForm.dirty || loginForm.touched )">
                        <span class="err-msg">
                        {{errMsgs.mandarotyFields}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                     <div class="field-con btn-con">
                        <button class="btn submit-btn login-btn" type="submit" title="Login">Login</button>
                    </div>
                    <div class="link-con">
                        <a class="link nav-link"
                        [routerLink]="redirectUrls?.signUp"
                        title="Signup">Sign up
                        </a>
                        <a class="link nav-link"
                            [routerLink]="redirectUrls?.forgotPassword"
                            title="Forgot Password">Forgot Password
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
