<nav class="navbar">

  <!-- App Details -->
  <div class="logo-con">
    <i
      *ngIf="showNavBar"
      class="menu-click-icon fa fa-bars light-icon"
      aria-hidden="true"
      (click)="toggleSideBar()"></i>
    <img class="logo logo-small" src="assets/images/main-logo.png">
    <!-- <span class="header-title">{{appDetails?.appName}}</span> -->
  </div>

  <!-- Side Bar -->
  <p-sidebar
    position="left"
    [(visible)]="showSideBar"
    [baseZIndex]="10000"
    [showCloseIcon]="true"
    styleClass="p-sidebar-sm">
    <div class="side-menu menu-wrap" id="navbarSupportedContent">
      <div class="menu-title">
        <img class="logo logo-small" src="assets/images/main-logo.png">
        <!-- <span class="header-title">Guardian RPM</span> -->
      </div>
      <ul class="navbar-nav mr-auto">
        <!-- Nav Items -->
        <li class="nav-item"
        [title]="item?.title"
        *ngFor="let item of menuList;let menuIndex=index">
          <a class="nav-link"
            [routerLink]="item?.link"
            [routerLinkActive]="'active'">
            <span class="menu-title">{{item?.title}}</span>
          </a>
        </li>
      </ul>
    </div>
  </p-sidebar>

  <!-- Menu Wrap -->
  <div
    *ngIf="showNavBar"
    class="header-menu menu-wrap" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <!-- Nav Items -->
      <li class="nav-item"
      [title]="item?.title"
      *ngFor="let item of menuList;let menuIndex=index">
        <a class="nav-link"
          [routerLink]="item?.link"
          [routerLinkActive]="'active'">
          <span class="menu-title">{{item?.title}}</span>
        </a>
      </li>
    </ul>
  </div>

  <!-- Nav Item - User Information -->
  <ul class="user-info">
      <li class="nav-item dropdown no-arrow">
              <div
                class="user-info" dropdown>
                  <span class="mr-2 d-lg-inline user-name">
                     {{userInfo?.name}}
                  </span>
                  <img
                      dropdownToggle
                      id="button-basic"
                      [src]="userInfo?.avatar"
                      class="img-profile rounded-circle pointer-mode"
                      aria-controls="dropdown-basic"
                      (error)="handleBrokenImage(this)">
                  <!-- Dropdown Menu -->
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu transform"
                    role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" (click)="onSettingsClick()">
                      <i
                        class="fa fa-gear colored ml-2"
                        title="Settings"></i><span class="pl-1">Settings</span></a>
                    </li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="onChangePasswordClick()">
                      <i
                        class="fa fa-key colored ml-2"
                        title="Change Password"></i><span class="pl-1">Change Password</span></a>
                    </li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="onLogoutClick()">
                      <i
                        class="fa fa-sign-out colored ml-2"
                        title="Logout"></i><span class="pl-1">Logout</span></a>
                    </li>
                  </ul>
              </div>
      </li>
  </ul>

</nav>
