import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as sha1 from 'sha1';
import * as _ from 'lodash';
import * as moment from 'moment';


import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { AlertMsg } from '@models/alert-msgs';

import { AppConstants } from './app-constants';
import { AuthConstants } from './auth-constants';
import { ToastrMessages } from './toastr-messages';
import { DropdownOption } from '@models/generic-types';
import { CsaFormConfig } from '@modules/csa-form/csa-form-config';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private router: Router,
    private alertMsgService: AlertMsgService
  ) { }

  hashPassword(passwordVal: any): string {
    return sha1(passwordVal);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Delete an item from the array
   */
  public deleteItem(currentArray: any[], deletedItem: any, mainKey: string, subKey: string = null): any[] {
    const updatedList = _.remove(currentArray, (item) => {
      if (subKey) {
        return item[mainKey][subKey] !== deletedItem[mainKey][subKey];
      } else {
        return item[mainKey] !== deletedItem[mainKey];
      }
    });
    return updatedList;
  }

  showResponseSuccess(defaultMsg: string, serverMessage: string) {
    const message: string = serverMessage ? serverMessage : defaultMsg;
    const alert: AlertMsg = {
      msg: message,
      title: ToastrMessages.TOASTR_TITLES.success
    };
    this.alertMsgService.showSuccessToster(alert);
  }

  showResponseError(defaultMsg: string, serverMessage?: string) {
    const message: string = serverMessage ? serverMessage : defaultMsg;
    const alert: AlertMsg = {
      msg: message,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.alertMsgService.showErrorToster(alert);
  }

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: Check the value of token in storage
  */
  checkTokenInStorage(): boolean {
    let retValue = false;
    retValue = localStorage.getItem(AuthConstants.localStorageVariables.access_token) ? true : false;
    return retValue;
  }

  rearrageToDropdownOptionFormat(listItems: any[], labelKey: string, valKey: string): DropdownOption[] {
    let dropdownList: DropdownOption[] = [];
    listItems.forEach(
      (item: any) => {
        let formattedItem: DropdownOption = {
          label: item[labelKey],
          value: item[valKey],
        };
        dropdownList.push(formattedItem);
      }
    );
    return dropdownList;
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Redirect user to landing page
    */
  redirectToLandingPage() {
      this.router.navigateByUrl(AppConstants.redirectUrls.client);
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Redirect user to login page
    */
  redirectToLogin() {
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

  checkFormStatus(userInfo): boolean {
    let retVal = true;
    if ((userInfo.applnStatus == CsaFormConfig.FORM_STATUS.default) || (userInfo.applnStatus == CsaFormConfig.FORM_STATUS.inProgress)) {
      retVal = false;
    }
    return retVal;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: find index of a particular item in an array
   */
  findItemIndex(itemsList: any[], selectedItemRefKey: any, refKey: string) {
    const index = _.findIndex(itemsList, item => item[refKey] === selectedItemRefKey );
    return index;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Converts UTC date to to local format
   */
  public convertToLocalTime(utcDate: string): any {
    let localDate = new Date();
    if (utcDate) {
      localDate = new Date(utcDate);
    }
    return localDate;
  }

  constructFullName(userData: any): string {
    const firstName = userData.fname ? userData.fname : '';
    const middleName = userData.mname ? userData.mname : '';
    const lastName = userData.lname ? userData.lname : '';
    let fullName = `${firstName} ${middleName} ${lastName}`;
    return fullName;
  }

  calculateAge(dob: any): number {
    let age = moment().diff(dob, 'years',false);
    return age;
  }

  constructDropdownFormat(rawList: any[], valueText: string, labelText: string, retainOriginal = false ): any[] {
    const formattedList = rawList.map(
      (item) => {
        if (retainOriginal) {
          item = item;
        }
        item.label = item[labelText];
        item.value = item[valueText];
        item.key = item[labelText] ? item[labelText].toLowerCase(): '';
        return item;
      }
    );
    return formattedList;
  }


}
